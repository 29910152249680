import * as React from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./itemgrid.css";

export class ItemGridItem {
    id: string = "a";
    category: string = "Category";
    title: string = "Unnamed title";
    image: string = "";
    pointOfInterest: number = 80;
    backgroundColor: string = "#814A0E";
    bgClass: string = "";
    useBackground: boolean = false;
    content: JSX.Element = (<div></div>)
}

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };

export default class ItemGrid extends React.PureComponent<{ items: ItemGridItem[] }, { selectedId: string }> {
    constructor(props: any) {
        super(props);

        this.state = {
            selectedId: ""
        };

        this.Item = this.Item.bind(this);
        this.List = this.List.bind(this);
        this.Card = this.Card.bind(this);
        this.Store = this.Store.bind(this);
        this.CardClick = this.CardClick.bind(this);
        this.CardAwayClick = this.CardAwayClick.bind(this);
    }

    Store(props: any) {
        return (
            <>
                <this.List selectedId={this.state.selectedId} />

                <AnimatePresence>
                    {this.state.selectedId.length > 0 && <this.Item id={this.state.selectedId} key="item" />}
                </AnimatePresence>
            </>
        );
    }

    Item(props: any) {
        const s = this.props.items.find(item => item.id === props.id);

        return (
            <>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.15 } }}
                    transition={{ duration: 0.2, delay: 0.15 }}
                    style={{ pointerEvents: "auto" }}
                    className="overlay" onClick={this.CardAwayClick}>

                </motion.div>

                <div className="card-content-container open">
                    <motion.div className="card-content" layoutId={`card-container-${s?.id}`}>
                        <motion.div
                            className="card-image-container"
                            layoutId={`card-image-container-${s?.id}`}>
                                {s?.useBackground === false && <img className="card-image" src={s?.image} alt="" onClick={this.CardAwayClick} />}
                                {s?.useBackground === true && <div className={`card-image-alt ${s?.bgClass}`} onClick={this.CardAwayClick} />}
                        </motion.div>
                        <motion.div
                            className="title-container"
                            layoutId={`title-container-${s?.id}`}>
                            <span className="category">{s?.category}</span>
                            <h2>{s?.title}</h2>
                        </motion.div>

                        <motion.div className="content-container" animate>
                            {s?.content}
                        </motion.div>
                    </motion.div>
                </div>
            </>
        );
    }

    CardClick(id: string) {
        this.setState({
            selectedId: id
        });
    }

    CardAwayClick() {
        this.setState({
            selectedId: ""
        });
    }

    Card(props: any) {
        var id = props.id;
        var title = props.title;
        var category = props.category;
        var image = props.image;
        var useBack: boolean = props.useBackground;
        var bgClass: string = props.bgClass;

        return (
            <li className={`card`}>
                <div className="card-content-container" onClick={() => this.CardClick(id)}>
                    <motion.div className="card-content" layoutId={`card-container-${id}`}>
                        <motion.div
                            className="card-image-container"
                            layoutId={`card-image-container-${id}`}
                        >
                            {useBack === false && <img className="card-image" src={image} alt="" />}
                            {useBack === true && <div className={`card-image-alt ${bgClass}`}  />}
                        </motion.div>
                        <motion.div
                            className="title-container"
                            layoutId={`title-container-${id}`}
                        >
                            <span className="category">{category}</span>
                            <h2>{title}</h2>
                        </motion.div>
                    </motion.div>
                </div>
            </li>
        );
    }

    List(props: any) {
        const selectedId: string = props.selectedId;

        return (
            <ul className="card-list">
                {this.props.items.map(card => (
                    <this.Card key={card.id} {...card} isSelected={card.id === selectedId} />
                ))}
            </ul>
        );
    }

    public render() {
        return (
            <div className="grid-container">
                <AnimateSharedLayout type="crossfade">
                    <this.Store />
                </AnimateSharedLayout>
            </div>
        );
    }
}