import * as React from 'react';
import { State } from '../state';
import YouTubeEmbed from './partial/YoutubeEmbed';

export default class Home extends React.PureComponent<{}> {
    colorClass: string;
    level: number = 0;

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan medium"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/nEzHyuuAJiY?rel=0" />

                    <div className="embedCenterParent">
                        <iframe className="embedKartice" src="https://indd.adobe.com/embed/8d361dbe-1ce4-4199-808f-9a0c37dbc986?startpage=1&allowFullscreen=true"
                            width="525px" height="450px" frameBorder="0" allowFullScreen={true}></iframe>
                    </div>
                </div>
            </>
        );
    }
};
