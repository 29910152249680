import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level4 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 4;

    items: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Smučarski tabor",
            pointOfInterest: 80,
            image: "/img/covers/rogla-cover.jpg",
            backgroundColor: "#814A0E",
            content: (
                <div>
                    <p>
                        Za dijake splošne in umetniške gimnazije je v okviru obveznih izbirnih vsebin organiziran smučarski tabor na Rogli. Tabor je namenjen izboljšanju smučarskih veščin in ga vodijo učitelji športne vzgoje ter učitelji smučanja. Na taboru dijaki koristno preživijo čas v naravi, ne manjka pa tudi zabave.
                    </p>
                    <img src="/img/covers/rogla-inner-1.jpg" className="fill" />
                    <img src="/img/covers/rogla-inner-2.jpg" className="fill" />
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "f",
            category: "",
            title: "Matematični vikend",
            pointOfInterest: 120,
            image: "/img/covers/mat-cover.png",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Matematičnimi vikendi na Gimnaziji Ptuj so izziv zahtevnejšim in radovednejšim dijakom na tem področju. Ne le, da skupaj rešujejo zahtevnejše izzive, ki jim koristijo na vseh ravneh matematičnih tekmovanj, temveč jim tudi  omogočijo druženje z vrstniki, ki imajo enake interese in cilje.
                    </p>
                    <img src="/img/covers/mat-inner.jpg" className="fill" />
                    <p>
                        Matematični vikendi so priložnost za oblikovanje gimnazijske ekipe matematičnih navdušencev, ki drug drugega spodbujajo in motivirajo na poti razvijanja potencialov na matematičnem področju.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(4);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/cqmCmWOAKXE?rel=0" />
                    <br />

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/unesco/0.jpg",
                                    "/img/sliders/unesco/1.jpg",
                                    "/img/sliders/unesco/2.jpg",
                                    "/img/sliders/unesco/3.jpg",
                                    "/img/sliders/unesco/4.jpg",
                                    "/img/sliders/unesco/5.jpg",
                                    "/img/sliders/unesco/6.jpg",
                                    "/img/sliders/unesco/7.jpg",
                                    "/img/sliders/unesco/8.jpg",
                                    "/img/sliders/unesco/9.jpg",
                                    "/img/sliders/unesco/10.jpg",
                                    "/img/sliders/unesco/11.jpg",
                                    "/img/sliders/unesco/12.jpg",
                                    "/img/sliders/unesco/13.jpg",
                                    "/img/sliders/unesco/14.jpg",
                                    "/img/sliders/unesco/15.jpg",
                                    "/img/sliders/unesco/16.jpg",
                                    "/img/sliders/unesco/17.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/dangan/0.jpg",
                                    "/img/sliders/dangan/1.jpg",
                                    "/img/sliders/dangan/2.jpg",
                                    "/img/sliders/dangan/3.jpg",
                                    "/img/sliders/dangan/4.jpg",
                                    "/img/sliders/dangan/5.jpg",
                                    "/img/sliders/dangan/6.jpg",
                                    "/img/sliders/dangan/7.jpg",
                                    "/img/sliders/dangan/8.jpg",
                                    "/img/sliders/dangan/9.jpg",
                                    "/img/sliders/dangan/10.jpg",
                                    "/img/sliders/dangan/11.jpg",
                                    "/img/sliders/dangan/12.jpg",
                                    "/img/sliders/dangan/13.jpg",
                                    "/img/sliders/dangan/14.jpg",
                                    "/img/sliders/dangan/15.jpg",
                                    "/img/sliders/dangan/16.jpg",
                                    "/img/sliders/dangan/17.jpg",
                                    "/img/sliders/dangan/18.jpg",
                                    "/img/sliders/dangan/19.jpg",
                                    "/img/sliders/dangan/20.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/erasmus/0.jpg",
                                    "/img/sliders/erasmus/1.jpg",
                                    "/img/sliders/erasmus/2.jpg",
                                    "/img/sliders/erasmus/3.jpg",
                                    "/img/sliders/erasmus/4.jpg",
                                    "/img/sliders/erasmus/5.jpg",
                                    "/img/sliders/erasmus/6.jpg",
                                    "/img/sliders/erasmus/7.jpg",
                                    "/img/sliders/erasmus/8.jpg",
                                    "/img/sliders/erasmus/9.jpg",
                                    "/img/sliders/erasmus/10.jpg",
                                    "/img/sliders/erasmus/11.jpg",
                                    "/img/sliders/erasmus/12.jpg",
                                    "/img/sliders/erasmus/13.jpg",
                                    "/img/sliders/erasmus/14.jpg",
                                    "/img/sliders/erasmus/15.jpg",
                                    "/img/sliders/erasmus/16.jpg",
                                    "/img/sliders/erasmus/17.jpg",
                                    "/img/sliders/erasmus/18.jpg",
                                    "/img/sliders/erasmus/19.jpg",
                                    "/img/sliders/erasmus/20.jpg",
                                    "/img/sliders/erasmus/21.jpg",
                                    "/img/sliders/erasmus/22.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <YouTubeEmbed src="https://www.youtube.com/embed/0ZSycF3EslA?rel=0" />

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/angles/0.jpg",
                                    "/img/sliders/angles/1.jpg",
                                    "/img/sliders/angles/2.jpg",
                                    "/img/sliders/angles/3.jpg",
                                    "/img/sliders/angles/4.jpg",
                                    "/img/sliders/angles/5.jpg",
                                    "/img/sliders/angles/6.jpg",
                                    "/img/sliders/angles/7.jpg",
                                    "/img/sliders/angles/8.jpg",
                                    "/img/sliders/angles/9.jpg",
                                    "/img/sliders/angles/10.jpg",
                                    "/img/sliders/angles/11.jpg",
                                    "/img/sliders/angles/12.jpg",
                                    "/img/sliders/angles/13.jpg",
                                    "/img/sliders/angles/14.jpg",
                                    "/img/sliders/angles/15.jpg",
                                    "/img/sliders/angles/16.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/slo/0.jpg",
                                    "/img/sliders/slo/1.jpg",
                                    "/img/sliders/slo/2.jpg",
                                    "/img/sliders/slo/3.jpg",
                                    "/img/sliders/slo/4.jpg",
                                    "/img/sliders/slo/5.jpg",
                                    "/img/sliders/slo/6.jpg",
                                    "/img/sliders/slo/7.jpg",
                                    "/img/sliders/slo/8.jpg",
                                    "/img/sliders/slo/9.jpg",
                                    "/img/sliders/slo/10.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/knjiznica/0.jpg",
                                    "/img/sliders/knjiznica/1.jpg",
                                    "/img/sliders/knjiznica/2.jpg",
                                    "/img/sliders/knjiznica/3.jpg",
                                    "/img/sliders/knjiznica/4.jpg",
                                    "/img/sliders/knjiznica/5.jpg",
                                    "/img/sliders/knjiznica/6.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/glasba/0.jpg",
                                    "/img/sliders/glasba/1.jpg",
                                    "/img/sliders/glasba/2.jpg",
                                    "/img/sliders/glasba/3.jpg",
                                    "/img/sliders/glasba/4.jpg",
                                    "/img/sliders/glasba/5.jpg",
                                    "/img/sliders/glasba/6.jpg",
                                    "/img/sliders/glasba/7.jpg",
                                    "/img/sliders/glasba/8.jpg",
                                    "/img/sliders/glasba/9.jpg",
                                    "/img/sliders/glasba/10.jpg",
                                    "/img/sliders/glasba/11.jpg",
                                    "/img/sliders/glasba/12.jpg",
                                    "/img/sliders/glasba/13.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/uzg/0.jpg",
                                    "/img/sliders/uzg/1.jpg",
                                    "/img/sliders/uzg/2.jpg",
                                    "/img/sliders/uzg/3.jpg",
                                    "/img/sliders/uzg/4.jpg",
                                    "/img/sliders/uzg/5.jpg",
                                    "/img/sliders/uzg/6.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>


                    <br />
                    <ItemGrid items={this.items} />
                    <h1 className="center">Zbor</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/tCodo7_yKEA?rel=0" />
                    <br />
                    <h1 className="center">Šolski band</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/kYHl8uk39wc?rel=0" />
                    <br />
                    <h1 className="center">Orkester</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/FD_kr2S4bQU?rel=0" />
                    <br />
                    <h1 className="center">Unescov tek</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/kAz7ICDZPEk?rel=0" />
                    <br />
                    <h1 className="center">Projektni teden</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/7JM2kl3OrgA?rel=0" />                    
                </div>
            </>
        );
    }
}