import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import { State } from '../../state';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level8 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 8;

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(8);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/HX41NPvH2fA?rel=0" />

                    <h1>Športni dosežki</h1>
                    <div className="fancyBox">
                        <p><b>ŠPORTNI DOSEŽKI SEDANJIH DIJAKOV</b></p>
                        <this.readMore1 />
                        <br /><br />
                        <p><b>DOSEŽKI ŠPORTNIH EKIP GIMNAZIJE PTUJ V ŠOLSKEM LETU 2020/2021</b></p>
                        <this.readMore2 />
                        <br /><br />
                        <p><b>DOSEŽKI ŠPORTNIH EKIP GIMNAZIJE PTUJ V ŠOLSKEM LETU 2019/2020</b></p>
                        <this.readMore3 />
                        <br /><br />
                        <p><b>DOSEŽKI ŠPORTNIH EKIP GIMNAZIJE PTUJ V ŠOLSKEM LETU 2018/2019</b></p>
                        <this.readMore4 />
                    </div>

                    <br />

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/sport/0.jpg",
                                    "/img/sliders/sport/1.jpg",
                                    "/img/sliders/sport/2.jpg",
                                    "/img/sliders/sport/3.jpg",
                                    "/img/sliders/sport/4.jpg",
                                    "/img/sliders/sport/5.jpg",
                                    "/img/sliders/sport/6.jpg",
                                    "/img/sliders/sport/7.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <br />

                    <YouTubeEmbed src="https://www.youtube.com/embed/YGSlVVgbBpM?rel=0" />
                    <br />

                    <div className="fancyBox">
                        <p><b>ŠPORTNI USPEHI NEKDANJIH DIJAKOV</b></p>
                        <this.readMore5 />
                    </div>
                </div>
            </>
        );
    }

    readMore1() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <div className="scroller">

                        NIKA STRAŠEK (TENIS)
                        <ul>
                            <li>455. mesto na ITF lestvici</li>
                            <li>2. mesto državno prvenstvo, deklice do 16 let, 2019</li>
                            <li>2. mesto DVOJICE  državno prvenstvo, deklice do 16 let, 2019</li>
                            <li>2. mesto posamezno, ITF Abidjan, 2019</li>
                            <li>2. mesto dvojice, ITF Abidjan, 2019</li>
                            <li>3-4. mesto posamezno, ITF Abidjan</li>
                            <li>2. mesto, ITF Abidjan, 2019</li>
                            <li>reprezentantka ekipe Slovenije na tekmovanju WINTER CUP, Lousada, Portugalska, 2019, deklice do 16 let</li>
                            <li>reprezentantka ekipe Slovenije na tekmovanju IC Rod Laver cup, Frankfurt, Nemčija, 2019, deklice do 16 let, 3. mesto</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 13.1. – 18.1.2020: 1. mesto posamezno</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 13.1. – 18.1.2020: 1. mesto dvojice</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 20.1. – 25.1.2020: ¼ finale posamezno</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 20.1. – 25.1.2020: ½ finale dvojice</li>
                            <li>ITF Radomlje, Slovenija, 8.9. – 13.9. 2020, ¼ finale posamezno</li>
                            <li>1. mesto na ligaškem tekmovanju za članice, 21.6. – 28.6.2021, <b>NASLOV EKIPNE ČLANSKE DRŽAVNE PRVAKINJE</b> z ekipo ŽTK Maribor</li>
                            <li>3.-4. mesto (½ finale) državno prvenstvo, mladinke do 18 let, 22.3.-26.3.2021</li>
                            <li>mesto DVOJICE državno prvenstvo, mladinke do 18 let, 22.3.-26.3.2021 - <b>DRŽAVNA PRVAKINJA V DVOJICAH</b> (Nika Strašek in Brina Šulin) ITF mednarodni turnirji U18</li>
                            <li>ITF Abidjan, Slonokoščena obala, 20.4. – 25.4.2021, ¼ finale posamezno in ½ finale dvojice</li>
                            <li>ITF Abidjan, Slonokoščena obala, 27.4. – 2.5.2021, ¼ finale posamezno in ½ finale dvojice</li>
                            <li>ITF Maribor, 16.8. – 21.8.2021, ¼ finale posamezno</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 11.10. – 16.10.2021: 1. mesto dvojice</li>
                            <li>ITF Široki brijeg, Bosna in Hercegovina, 18.10. – 23.10.2021: ¼ finale posamezno in ½ finale dvojice</li>
                        </ul>

                        ARWEN NYLANDER (HOKEJ)
                        <ul>
                            <li>članica HK: HDK Maribor, HK Celje, HK Olimpija, in trenutno MODO Hockey 2 (Švedska)</li>
                        </ul>

                    </div>
                )}
            </>
        );
    }

    readMore2() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <div className="scroller">
                        AKROBATIKA
                        <ul>
                            <li>1. mesto na državnem prvenstvu v akrobatiki</li>
                        </ul>
                        <br />

                        ODBOJKA
                        <ul>
                            <li>1. mesto občinsko prvenstvo dijakinje in dijaki</li>
                        </ul>
                        <br />

                        KOŠARKA
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijake</li>
                        </ul>
                        <br />

                        NOGOMET
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijakinje</li>
                            <li>1. mesto na področnem prvenstvu za dijakinje</li>
                        </ul>
                        <br />
                    </div>
                )}
            </>
        );
    }

    readMore3() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <div className="scroller">
                        ODBOJKA
                        <ul>
                            <li>1. mesto občinsko prvenstvo dijakinje in dijaki</li>
                            <li>1. mesto na področnem prvenstvu za dijakinje</li>
                            <li>2. mesto na področnem prvenstvu za dijake</li>
                            <li>3. mesto dekleta na polfinalu državnega prvenstva</li>
                        </ul>
                        <br />

                        ATLETIKA
                        <ul>
                            <li>2. mesto na ekipnem področnem prvenstvu za dijakinje</li>
                        </ul>
                        <br />

                        NOGOMET
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijakinje in dijake</li>
                            <li>2. mesto na področnem prvenstvu za dijakinje</li>
                            <li>2. mesto na področnem prvenstvu za dijake</li>
                        </ul>
                        <br />

                        KOŠARKA
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijake</li>
                        </ul>
                    </div>
                )}
            </>
        );
    }

    readMore4() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <div className="scroller">
                        ROKOMETNA EKIPA GIMNAZIJE PTUJ
                        <ul>
                            <li>zmagovalci mednarodnega rokometnega turnirja Daf Cup Zaprešič  2018 in 2019</li>
                        </ul>
                        <br />

                        ODBOJKA
                        <ul>
                            <li>1. mesto občinsko prvenstvo dijakinje in dijaki</li>
                            <li>1. mesto na področnem prvenstvu za dijakinje</li>
                            <li>3. mesto dekleta na polfinalu državnega prvenstva</li>
                        </ul>
                        <br />

                        ATLETIKA
                        <ul>
                            <li>2. mesto na ekipnem področnem prvenstvu za dijakinje</li>
                        </ul>
                        <br />

                        NOGOMET
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijakinje in dijake</li>
                        </ul>
                        <br />

                        KOŠARKA
                        <ul>
                            <li>1. mesto na občinskem prvenstvu za dijake</li>
                            <li>1. mesto na področnem tekmovanju</li>
                            <li>2. mesto na ekipnem področnem prvenstvu za dijakinje</li>
                        </ul>
                    </div>
                )}
            </>
        );
    }

    readMore5() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <div className="scroller">

                        SVEN LAH (TENIS)
                        <ul>
                            <li>1. mesto na državnem prvenstvu posamezno do 16 let </li>
                            <li>najboljši slovenski mladinec 2015 in 2016</li>
                            <li>1. mesto na lestvici TZS do 18 let (Teniška zveza Slovenije)</li>
                            <li>ITF Junior Bruchköbel (Nemčija) - 1. mesto (posamezno) - 2015</li>
                            <li>ITF European Junior championship (Švica) - 2015</li>
                            <li>ITF Junior Triglav open  - 1. mesto (posamezno) - 2015</li>
                            <li>ITF Junior circuit Maribor  - 1. mesto (posamezno) - 2015</li>
                            <li>ITF Junior Novi Sad (SRBIJA) - 1. mesto (dvojice)  - 2015</li>
                            <li>ITF Junior Orange bowl (USA) - med 16 (posamezno); med 8 (dvojice) -  2015</li>
                            <li>ITF Junior Copa Barranquilla (Kolumbija) - 3. mesto (dvojice)  - 2016</li>
                            <li>ITF Junior Ibarra (Ekvador) - 3. mesto (dvojice) - 2016</li>
                            <li>ITF Mödling (Avstrija) - 3. mesto (posamezno); 1. mesto (dvojice) - 2016</li>
                            <li>ITF Bochum (Nemčija) - 3. mesto (posamezno) – 2016</li>
                            <li>895. mesto na svetovni profesionalni lestvici (ATP)</li>
                            <li>74. mesto na svetovni mladinski lestvici (ITF) - do 18 let</li>
                            <li>fakultetni turnirji v okviru študija v ZDA</li>
                        </ul>
                        <br />

                        MARTIN MILEC (NOGOMET)
                        <ul>
                            <li>član NK Aluminij, Maribor, Standard Liege, Roda JC in trenutno Nk Maribor</li>
                            <li>član slovenskih reprezentanc do 19, 21 in članske reprezentance</li>
                            <li>5x državni prvak z NK Maribor</li>
                            <li>2x pokalni zmagovalec Slovenije z NK Maribor</li>
                            <li>pokalni zmagovalec Belgije z NK Standard Liegom</li>
                        </ul>
                        <br />

                        INO ALDO ILEŠIČ (KOLESARSTVO)
                        <ul>
                            <li>član Pro tour ekipe</li>
                            <li>udeleženec dirk: Dirka po Slovenije, Giro dele Region, Tour of China, Tour do Rio Red Hook Crit</li>
                        </ul>
                        <br />

                        VERONIKA DOMJAN (ATLETIKA, MET DISKA)
                        <ul>
                            <li>455. mesto na ITF lestvici</li>
                            <li>2. mesto državno prvenstvo, deklice do 16 let, 2019 </li>
                            <li>2. mesto DVOJICE  državno prvenstvo, deklice do 16 let, 2019</li>
                            <li>2. mesto posamezno, ITF Abidjan, 2019</li>
                            <li>2. mesto dvojice, ITF Abidjan, 2019</li>
                            <li>3-4. mesto posamezno, ITF Abidjan</li>
                            <li>2. mesto, ITF Abidjan, 2019</li>
                            <li>reprezentantka ekipe Slovenije na tekmovanju WINTER CUP, Lousada, Portugalska, 2019, deklice do 16 let</li>
                            <li>reprezentantka ekipe Slovenije na tekmovanju IC Rod Laver cup, Frankfurt, Nemčija, 2019, deklice do 16 let, 3. mesto</li>
                        </ul>
                        <br />

                        NINA KOLARIČ (ATLETIKA, SKOK V DALJINO)
                        <ul>
                            <li>večkratna članska državna prvakinja v skoku v daljino </li>
                            <li>aktualna rekorderka v dvorani (Torino 667 cm,  2009) in na prostem (Ptuj 678 cm,  2008)</li>
                            <li>udeleženka Olimpijskih iger, Peking, 2008 </li>
                            <li>3. mesto na Mediteranskih igrah, 2009</li>
                            <li>2. mesto na Sredozemskih igrah, 2013</li>
                            <li>4. mesto na dvoranskem evropskem prvenstvu, Italija 2009</li>
                        </ul>
                        <br />

                        MINA MARKOVIČ (ŠPORTNO PLEZANJE)
                        <ul>
                            <li>6x državna prvakinja Slovenije</li>
                            <li>skupna zmagovalka svetovnega pokala v težavnostnem plezanju v letih 2011, 2012 in 2015 </li>
                            <li>2. mesto v skupnem seštevku svetovnega pokala 2010, 2013 in 2014</li>
                            <li>1. mesto na 21 tekmah svetovnega pokala </li>
                            <li>Evropska prvakinja v težavnostnem plezanju 2015, Chamonix </li>
                            <li>Evropska podprvakinja v težavnostnem plezanju in kombinaciji 2015, 2017</li>
                            <li>1. mesto na svetovnih igrah v težavnostnem plezanju 2013 </li>
                            <li>vojaška svetovna prvakinja v težavnostnem plezanju 2018</li>
                        </ul>
                        <br />

                        BLAŽ ROLA (TENIS)
                        <ul>
                            <li>1. mesto, Slovenija Evropski ekipni prvak do 18 let, 2008</li>
                            <li>nastop na  Davisovem pokalu, 2010</li>
                            <li>zmagovalec ameriškega univerzitetnega prvenstva  NCAA  v dvojicah, 2012</li>
                            <li>zmagovalec ameriškega univerzitetnega prvenstva NCAA posamezno, 2013</li>
                            <li>2 zlati medalji na sredozemskih igrah v Turčiji, 2013</li>
                            <li>9 osvojenih turnirjev serije Futures, v dvojicah pa 6 turnirjev Futures in 1 Challenger</li>
                            <li>kvalifikacijska uvrstitev na glavni turnir za Grand Slam, 2014 </li>
                            <li>nastop na odprtem prvenstvu Avstralije, 2014</li>
                            <li>nastop na  turnirju Wimbledon, 2014</li>
                            <li>78. mesto na ATP lestvici</li>
                            <li>osvojen turnirji:   Kitajska – Guangzhou, 2013, Challenger Santos – Brazilija 2015 in Challenger Tallahassce – USA, 2017 -najboljši slovenski teniški igralec leta 2014 2015 </li>
                            <li>158. mesto na ATP lestvici</li>
                        </ul>

                        MAJA BEDRAČ (ATLETIKA, SKOK V DALJINO)
                        <ul>
                            <li>večkratna državna prvakinja v skoku v daljino v mladinskih kategorijah</li>
                            <li>nosilka državnih rekordov v mladinskih kategorijah</li>
                            <li>3. mesto na svetovnem prvenstvu za mlajše mladinke, 2015</li>
                            <li>1. mesto na Olimpijskem festivalu mladine, Tbilisi, 2015</li>
                            <li>1. mesto na Balkanskem ekipnem prvenstvu Velenje,2015</li>
                            <li>2. mesto na Evropskem prvenstvu za mlajše mladinke, Tbilisi, 2016</li>
                            <li>1. mesto na Balkanskem prvenstvu za mlajše mladine, Srbija, 2016	</li>
                            <li>1. mesto na Brixia (Italija) miting, , 2016</li>
                            <li>1. mesto na Slovenskem državnem prvenstvupri članicah, 2017  </li>
                            <li>1. mesto na Svetovni lestvici za mlajše mladinke, 2016  </li>
                        </ul>

                        JURČEK KORPIČ LESJAK (ATLETIKA, MET KLADIVA, KROGLA)
                        <ul>
                            <li>3. mesto na ekipnem prvenstvu Slovenije za mladince in mladinke – kladivo 38, 30 m</li>
                            <li>1. mesto na prvenstvu slovenije v suvanju krogle – 13,89 m</li>
                            <li>1. mesto na spomladanskem mitingu v metih – kladivo - 44,67m</li>
                            <li>1. mesto na drugem mednarodnem mitingu v metih – krogla 13,01m</li>
                            <li>1. mesto na ekipnem prvenstvu Slovenije za pionirje – kladivo </li>
                            <li>1. mesto na ekipnem prvenstvu Slovenije za pionirje – kladivo </li>
                        </ul>

                        LUKA SAGADIN  (KOLESARSTVO)
                        <ul>
                            <li>1. mesto na vn Gorje</li>
                            <li>5. mesto na državnem prvenstvu v kronometru</li>
                            <li>član slovenske kolesarske  reprezentance</li>
                            <li>1. mesto na dirki Radjugendtour, 2015		</li>
                            <li>43. mesto v kronometru na Svetovnem mladinskem prvenstvu za mladince, Italija</li>
                            <li>27. mesto na Evropskem prvenstvu v cestnem kolesarstvu za mladince, Danska 2017</li>
                            <li>1. mesto v 3.etapi Trophee Centre Morbihan</li>
                        </ul>

                        JURE MATJAŠIČ (NOGOMET)
                        <ul>
                            <li>član NK: Zavrč, Domžale; Aluminij; Drava; Celje</li>
                            <li>nekdanji član slovenske nogometne reprezentance </li>
                            <li>pokalni zmagovalec Slovenije z NK Domžale  2017 </li>
                        </ul>

                        NELI HOFMAN (NOGOMET)
                        <ul>
                            <li>članica ŽNK: MSM Ptuj, Telemach GMT Beltinci, Nona Pomurje Beltinci</li>
                            <li>članica slovenske reprezentance do 17 in 19 let</li>
                        </ul>

                        MAJA MAKORIČ (TENIS)
                        <ul>
                            <li>292. mesto na ITF junior lestvici leta 2018</li>
                            <li>zmagovalka turnirja Maribor 2019</li>
                        </ul>

                        MATJAŽ ROZMAN (NOGOMET)
                        <ul>
                            <li>član NK:  Aluminij, Interblock, Greuther Furth, Without Club, NK Rudar Velenje, NK Slaven Belupo,  NK Celje</li>
                            <li>državni prvak z NK Celje, 2020</li>
                            <li>pokalni zmagovalec z NK Interblock, 2009</li>
                        </ul>

                        TARA GORINŠEK (TENIS)
                        <ul>
                            <li>finalistka turnirja Poreč 2020</li>
                            <li>590. mesto na ITF lestvici</li>
                        </ul>

                        TAŠA ROJKO (KONJENIŠTVO)
                        <ul>
                            <li>ekipna zlata medalja na mladinskem Blakanskem prvenstvu v dresurnem jahanju</li>
                            <li>3. mesto v točkovanju za Pokal Slovenije</li>
                        </ul>

                        ZALA CUNK (ATLETIKA)
                        <ul>
                            <li>1. mesto v metu diska na PS za mlajše mladince in mladinke, Nova Gorica 2019</li>
                            <li>2. mesto v metu diska na PS za starejše mladince in mladinke, Novo mesto 2019</li>
                            <li>4. mesto v metu diska na četveroboju reprezentanc mlajših mladincev in mladink, Madžarska 2019</li>
                            <li>16. mesto na Svetovnem šolskem prvenstvu v rokometu, Portugalska 2010</li>
                        </ul>

                        BLAŽ VIDOVIČ (TENIS)
                        <ul>
                            <li>finale turnirja v Oberpullendorfu</li>
                            <li>polfinale turnirja v Tremosinu</li>
                            <li>492. mesto na ITF lestvici</li>
                        </ul>


                        ROKOMETNA EKIPA GIMNAZIJE PTUJ
                        <ul>
                            <li>zmagovalci mednarodnega rokometnega turnirja Daf Cup Zaprešič 2015, 2016, 2017, 2018 in 2019</li>
                        </ul>
                    </div>
                )}
            </>
        );
    }
}