import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import App from './App';
import { unregister } from './registerServiceWorker';
import { State } from './state';

// load state data from localStorage
State.loadData();

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

ReactDOM.render(
    <Router history={history}>
        <App history={history} />
    </Router>,
    document.getElementById('root'));

unregister();