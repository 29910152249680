import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import { motion } from "framer-motion"
import './custom.css'

import Level1 from './components/levels/Level1';
import Level2 from './components/levels/Level2';
import Level3 from './components/levels/Level3';
import Level4 from './components/levels/Level4';
import Level5 from './components/levels/Level5';
import Level6 from './components/levels/Level6';
import Level7 from './components/levels/Level7';
import Level8 from './components/levels/Level8';
import Level9 from './components/levels/Level9';
import Level10 from './components/levels/Level10';
import Level11 from './components/levels/Level11';
import Level12 from './components/levels/Level12';

import Locked from './components/Locked';
import GuardedRoute from './components/partial/GuardedRoute';
import { State } from './state';
import { History } from 'history';
import { API } from './helpers/apiHelper';

import { AiFillCloseCircle, AiFillLock } from 'react-icons/ai'
import Login from './components/admin/login';
import Dashboard from './components/admin/dashboard';
import { Redirect } from 'react-router';
import { LevelData } from './models/levelData';
import { LiveEvent } from './models/liveEvent';
import { runInThisContext } from 'vm';

export default class App extends React.PureComponent<{ history: History }, {
    password: string,
    unlocking: boolean,
    masterPassword: string,
    canRevealMasterPassword: boolean,
    passwordError: string,
    liveEvents: LiveEvent[],
    hideEventPopup: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            password: "",
            unlocking: false,
            masterPassword: "",
            canRevealMasterPassword: false,
            passwordError: "",
            liveEvents: [],
            hideEventPopup: false
        };

        this.levelUnlock = this.levelUnlock.bind(this);
        this.unlockLevelRequest = this.unlockLevelRequest.bind(this);
        this.levelChanged = this.levelChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.getUnlockButton = this.getUnlockButton.bind(this);
        this.setPasswordError = this.setPasswordError.bind(this);
        this.showLiveEvents = this.showLiveEvents.bind(this);
        this.hideLiveEvents = this.hideLiveEvents.bind(this);

        API.getCountdown().then(c => {
            if (c == null) {
                this.setState({
                    masterPassword: "",
                    canRevealMasterPassword: false
                });
            }
            else {
                this.setState({
                    masterPassword: c.password,
                    canRevealMasterPassword: c.password.length > 0
                });
            }
        });

        API.getLiveEvents().then(l => {
            this.setState({
                liveEvents: l
            });

            State.liveEvents = l;
        })
    }

    passwordChanged(event: any) {
        this.setState({
            password: event.target.value
        });
    }

    async unlockLevelRequest(pass: string, levelIndex: number) {

        if (!pass || pass.length == 0) return;

        this.setState({ unlocking: true });
        const unlockedLevel: LevelData | null | boolean = await API.unlockLevel(pass, levelIndex, State.currentLevel);
        this.setState({ unlocking: false });

        if (unlockedLevel == null) {          
            this.setPasswordError("Nepravilno geslo!");
            console.error("Incorrect password!");
        }
        else if (unlockedLevel === false) {
            this.setPasswordError("Prehiter si! Malo počakaj in poskusi znova");
            console.error("Too many requests!");
        }
        else {
            // redirect to newly unlocked page 

            // TODO: play some animation?
            this.setPasswordError("");

            const lv: any = unlockedLevel;
            this.levelUnlock(lv.index, true);
        }
    }

    errorTimeoutCounter = 0;
    setPasswordError(error: string) {
        this.setState({
            passwordError: error
        });

        const currentCount = ++this.errorTimeoutCounter;
        if (this.errorTimeoutCounter > 300000) this.errorTimeoutCounter = 0;

        setTimeout(() => {
            if (currentCount != this.errorTimeoutCounter) return;

            this.setState({
                passwordError: ""
            });
        }, 3300);
    }

    public levelChanged(level: number) {
        State.selectedLevel = level;
        this.forceUpdate();
    }

    public levelUnlock(level: number, redirect: boolean) {
        if (State.currentLevel < level) {
            State.setLevel(level);
            this.forceUpdate();
        }

        if (redirect) {
            this.props.history.push(State.getRedirectForLevel(level));
        }
    }

    getUnlockButton() {
        if (State.isOnMainPage() && State.currentLevel < 12) {
            return (
                <>
                    <div id="pbox">
                        <form onSubmit={(e) => e.preventDefault()} className="passwordForm">
                            <input autoComplete="off" className="passwordBox" type="text" name="name" value={this.state.password} onChange={this.passwordChanged} />
                            <button className={`button passwordButton ${(this.state.password.length == 0 ? "disabled" : "")}`} onClick={() => {
                                this.unlockLevelRequest(this.state.password, State.selectedLevel);
                                this.setState({
                                    password: ""
                                })
                            }}><AiFillLock size={30} />Odkleni</button>
                        </form>
                    </div>
                    {this.state.passwordError.length > 0 && (
                        <div className="errorBoxContainer">
                            <div className="errorBox">
                                {this.state.passwordError}
                            </div>
                        </div>
                    )}
                    {this.state.canRevealMasterPassword && (
                        <div className="skipBox">
                            Lahko tudi vse preskočite z geslom "{this.state.masterPassword}"
                        </div>
                    )}
                    {!this.state.canRevealMasterPassword && (
                        <div className="skipBox">
                            Geslo se nahaja v posnetku!
                        </div>
                    )}
                </>
            );
        }
        else return (
            <div></div>
        );
    }

    hideLiveEvents(){
        this.setState({
            hideEventPopup: true
        });
    }

    showLiveEvents() {
        // events are shown on level 3 anyway - so we can ignore it there
        if (!State.isOnMainPage() || 
            State.selectedLevel === 3 || 
            this.state.hideEventPopup === true) return ( <div></div> );

        let numLive = 0;
        this.state.liveEvents.forEach(l => numLive += l.isLive ? 1 : 0);
        if (numLive === 0) return ( <div></div> );

        // show current live events in the corner
        return (
            <div className="liveEventSideBox">
                <div className="liveEvent-circle blinking"></div>
                <div className="liveEvent-close" onClick={() => this.hideLiveEvents()}><AiFillCloseCircle /></div>
                <span>Dogodki v živo:</span>
                {this.state.liveEvents.map(l => {
                    if (!l.isLive) return;

                    return (
                        <div className="liveEvent-item" key={l.id}>
                            <div className="liveEvent-time">{l.startTime}</div>
                            <div className="liveEvent-link"><a href={l.link}>{l.caption}</a></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    public render() {
        const self = this;
        return (
            <React.Fragment>
                <Layout onLevelChanged={this.levelChanged}>
                    <Route exact path='/' component={Home} />
                    <GuardedRoute path='/level1' levelNumber={1} component={Level1} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level2' levelNumber={2} component={Level2} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level3' levelNumber={3} component={Level3} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level4' levelNumber={4} component={Level4} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level5' levelNumber={5} component={Level5} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level6' levelNumber={6} component={Level6} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level7' levelNumber={7} component={Level7} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level8' levelNumber={8} component={Level8} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level9' levelNumber={9} component={Level9} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level10' levelNumber={10} component={Level10} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level11' levelNumber={11} component={Level11} onLevelChanged={this.levelChanged} />
                    <GuardedRoute path='/level12' levelNumber={12} component={Level12} onLevelChanged={this.levelChanged} />
                    <Route path='/login' render={() => (<Login history={this.props.history} onLoaded={() => self.forceUpdate()} />)} />

                    <Route path='/dashboard' render={() => {
                        if (State.isLoggedIn()) return <Dashboard />;
                        else return <Redirect to={{ pathname: "/login" }} />
                    }} />

                    <Route path='/locked' component={Locked} />

                    <this.getUnlockButton />

                    <this.showLiveEvents />
                </Layout>
            </React.Fragment>)
    }
};
