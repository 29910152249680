import * as React from 'react';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level5 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 5;

    items: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Ekskurzije v Azijo",
            pointOfInterest: 80,
            image: "/img/covers/india-cover.jpg",
            backgroundColor: "#814A0E",
            content: (
                <div>
                    <p>
                        V Azijsko-evropsko učilnico (AEC) smo vstopili leta 2006 v okviru organizacije ASEF (Asia-Europe Foundation). Po začetnih projektih preko spleta se nam je odprla pot za izmenjave, najprej trikrat s šolo Millenium Institut iz Singapurja in sedaj že tretja izmenjava s St. Mark’s Senior Secondary Public School iz New Delhija v Indiji. Za dijake ta izmenjava pomeni srečanje s povsem drugo kulturo in načinom življenja.
                        Nekaj vtisov dijakov, ki so se udeležili zadnje izmenjave z Indijo …
                    </p>

                    <p>Indija. Dežela z bogato kulturo in s tradicijo, dežela velikih razlik in nasprotij. </p>
                    <p>
                        Januar 2020. Prvi kulturni šok takoj ob prihodu – kaos v prometu. Nihče ne upošteva prometnih predpisov. Revščina se na vsakem koraku meša z bogato kulturno zapuščino. Indijskim cestam vladata gneča in nered. Naslednje presenečenje v kopalnici, tako drugačni od tiste doma – ni tople vode. Za hišo z vrhunsko tehnološko opremo se je to zdelo povsem nepredstavljivo.
                        Drugačnost, barvitost, začinjena hrana ter dezinfekcijska sredstva so bili naši sopotniki na vsakem koraku. Nekateri smo prvi dan preživeli v mogočnih templjih in celo sodelovali pri verskih obredih, drugi so se udeležili tradicionalne indijske poroke, za vse pa je bil dan poln presenečenj in nepozabnih trenutkov.
                        Na izletu po Indiji smo si ogledali Tadž Mahal, nacionalni park Rathambore, palačo Fatehpur Sikri, Jaipur, Amber Fort. Obiskali smo številne templje in spoznali religije v Indiji. Preizkusili smo se tudi v kuhanju indijskih jedi.
                    </p>

                    <img className="fill" src="/img/covers/india-inner.jpg" />
                    <p>Alja Rozman: »Še danes z velikim zanosom pripovedujem o barvitosti Indije. O drugačnosti. Ne morem  mimo revščine, ki je v indijski vsakdan vtkana na vsakem koraku. O v kartonskih škatlah živečih na ulicah. O bosih služabnikih na marmornatih ploščah. O kaosu na cestah. O gostoljubnosti. Drugačni hrani. Mogočni zgodovini. Še se bom vrnila. Indija je name naredila velik vtis. Je izkušnja, ki jo bomo popotniki te izmenjave nosili v srcu celo življenje.«</p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "f",
            category: "",
            title: "Burghausen",
            pointOfInterest: 120,
            image: "/img/covers/burghausen-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p><b>Skoraj kot doma</b></p>
                    <p>Izmenjava med Gimnazijo Ptuj in gimnazijo Aventinus Gymnasium iz Burghausna v Nemčiji poteka že 20 let. </p>
                    <p>
                        Bavarsko mestece, ki  je od leta 2001 pobrateno s Ptujem, od sosednje Avstrije loči le reka Salzach. Nad mestom se razprostira in kraljuje najdaljši grad v Evropi, dolg 1043 m, ki ima kar šest dvorišč. Izmenjava je izjemna izkušnja, saj je mesto presenetljivo podobno našemu, hkrati pa povsem drugačno. Vrstniki, gostitelji, živijo podobno kot mi, zato ni težko najti stika, hkrati pa je dovolj razlik, začenši z jezikom, da sodelujoče čaka zanimiva medkulturna izkušnja.
                        Je tudi šola podobna naši? Velika, moderna stavba, lepo opremljene učilnice, odprti dijaki, morda pri pouku aktivnejši … Veliko je podobnosti in tako pravzaprav ne čudi, da je sodelovanje med šolama že zdavnaj preseglo meje klasične izmenjave. Šolo v Burghausnu so v 20 letih obiskali skoraj vsi profesorji Gimnazije Ptuj, dijaki iz Burghausna pa so sodelovali tudi v ptujskih projektih, kot so nemški projektni teden, Unescov tek, konferenca MUN … Vse to gotovo ne bi bilo mogoče brez profesorja Stefana Angstla, organizatorja tega sodelovanja na nemški strani, ki je s svojo odprtostjo, širino ter izjemno gostoljubnostjo zaščitni znak vsake izmenjave.
                        Tudi gostiteljske družine so vedno skrbno izbrane. Dijaki imajo priložnost nekaj dni živeti njihov način življenja, spoznati šolo in mesto, pogosto obiščejo tudi München ali Salzburg, poskusijo tipične bavarske jedi, razvijajo veščine sporazumevanja v tujih jezikih, predvsem pa sklepajo nova prijateljstva, ki marsikdaj ostanejo trajna.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "es",
            category: "",
            title: "Poljska",
            pointOfInterest: 120,
            image: "/img/covers/polska-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>Od leta 2007 za dijake 4. letnikov vsako leto organiziramo ogled koncentracijskega taborišča Auschwitz – Birkenau in mesta Krakov. </p>

                    <p>
                        Menim, da bi si vsak posameznik moral vsaj enkrat v življenju ogledati obe taborišči in se seznaniti s posledicami, ki jih prinese vojna. Tako si nihče na tem svetu ne bi več želel vojne in jo razumel kot možnost reševanja kakršnegakoli konflikta. (Nataša)
                    </p>
                    <p>
                        Že takoj ob vstopu v Auschwitz (Oświęcim) se je začutila posebna tišina, tista,  ki človeka napolni s čustvi in z grozo. Posebej me je pretreslo, ko smo listali po več metrov dolgi knjigi z imeni umrlih. Zadnji del  te ima veliko praznih strani, kar simbolično ponazarja dejstvo, da imen vseh umrlih ne bomo nikoli poznali …
                        Ta ekskurzija je ena izmed najbolj zahtevnih in najbolj poučnih. Tam začutiš hvaležnost, da si svoboden,  da živiš v miru in se zaveš, da je mir treba ohranjati.  (Ana)
                    </p>
                    <p>
                        Ko sem po ogledu premišljevala o vsem videnem, nikakor nisem razumela, kako je človek zmožen tako brutalnega načina iztrebljanja sočloveka. Auschwitz je eden izmed redkih krajev na svetu, kjer rek Upanje umre zadnje drži kot pribito.  (Urška)
                    </p>

                    <p>
                        Nisem občutila le groze, ampak me je ves ogled spremljal tudi občutek krivde. Kakšne grozne muke so morali pretrpeti, da mi lahko danes živimo drugače, lepše.  Pretresljivo je bilo poslušati o žrtvah, večinoma Judih, tudi otrocih in naših vrstnikih, videti sobe, polne las, čevljev, očal, kovčkov … (Nataša)
                    </p>

                    <img className="fill" src="/img/covers/polska-inner.jpg" />
                    <p>Ekskurzijo vedno zaključimo v čudovitem Krakovu, ki nas očara.</p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "e",
            category: "",
            title: "London",
            pointOfInterest: 120,
            image: "/img/covers/london-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Za dijake 1. letnikov organiziramo 4-dnevno ekskurzijo v britansko prestolnico London.
                    </p>
                    <p>
                        Za mlade je to svojevrstno doživetje, pretkano z vrvežem milijonskega mesta, čudovito zgodovinsko arhitekturo, ki jo dopolnjujejo spektakularne moderne gradnje in številne znamenitostmi, ki so jih dotlej poznali predvsem s fotografij in iz filmov.
                    </p>
                    <img className="fill" src="/img/covers/london-inner-2.jpg" />
                    <p>Fotografiranje pred Big Benom.</p>
                    <img className="fill" src="/img/covers/london-inner-4.jpg" />
                    <p>Nepozabna vožnja, ki ponuja čudovit razgled na celotno mesto – London Eye!</p>
                    <img className="fill" src="/img/covers/london-inner-3.png" />
                    <p>Ogled astronomskega observatorija v Greenwichu.</p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "d",
            category: "",
            title: "Dublin",
            pointOfInterest: 120,
            image: "/img/covers/dublin-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Ekskurzija v Dublin je za vse udeležence nepozabno doživetje. Organiziramo jo za dijake 1. letnikov. Mesto se ponaša z mnogimi zanimivostmi: od zgodovinskih spomenikov, muzejev in galerij do živahnih ulic s tradicionalnimi pivnicami (pubi).
                    </p>
                    <p>
                        Ogledamo si Narodni muzej Irske, vikinški muzej Dublina in Narodno galerijo.
                    </p>
                    <img className="fill" src="/img/covers/dublin-inner-1.jpg" />
                    <img className="fill" src="/img/covers/dublin-inner-2.JPG" />
                    <p>Kip, posvečen Molly Malone, prodajalki rib, ki je veljala za najlepšo žensko takratnega časa in jo Irci opevajo v svoji neuradni himni – In Dublin's fair city. </p>
                    <img className="fill" src="/img/covers/dublin-inner-3.JPG" />
                    <p>Podamo se tudi na enodnevni izlet proti zahodni obali do najznačilnejšega irskega mesteca Galway.</p>
                    <p>
                        In naprej  do veličastnih moherskih klifov. Ta naravni spomenik ne slovi le po dih jemajočih klifih, temveč tudi po dejstvu, da v času parjenja nudi zavetje 20.000 parom ptic.
                    </p>
                    <img className="fill" src="/img/covers/dublin-inner-4.JPG" />
                    <p>
                        Iz Dublina pa se ne moremo  vrniti, ne da bi obiskali znamenito pivovarno Guiness, kjer si ogledamo celoten postopek priprave piva in uživamo ob prekrasnem pogledu na Dublin s tovarniške razgledne ploščadi.
                    </p>
                    <img className="fill" src="/img/covers/dublin-inner-5.JPG" />
                    <p>
                        Ekskurzija na Irsko in Dublin prav gotovo ostane v lepem spominu.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "dhjy",
            category: "",
            title: "Dunaj",
            pointOfInterest: 120,
            image: "/img/covers/dunaj-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Ekskurzija na Dunaj je celodnevna. Z dijaki najpogosteje obiščemo razstave velikih umetniških imen, ki jih pripravljajo v Umetnostnozgodovinskem muzeju in Albertini, Belvederu … Tako smo nazadnje v novembru 2019 v Umetnostnozgodovinskem muzeju obiskali razstavi baročnih umetnikov Caravaggia in Berninija ter izjemno pregledno razstavo slikarja nemške renesanse, Albrechta Dürerja, na kateri smo občudovali znamenitega Zajčka, Kristusa med pismouki, sv. Hieronima … videli smo tudi njegove znamenite grafike od lesorezov cikla Apokalipsa, Melanholija, sv. Hieronim v študijski sobi … Že vrsto let s ptujskimi gimnazijci obiskujemo te velike in kvalitetne razstave, ki pogosto vključujejo tudi delavnice. Če na program nismo vključili ogleda razstav, smo si z dijaki ogledali mestne znamenitosti.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "dlk",
            category: "",
            title: "Firence",
            pointOfInterest: 120,
            image: "/img/covers/firence-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Tradicionalna na ptujski gimnaziji je tudi ekskurzija v Firence, ki vključuje še oglede znamenitosti Siene, Pise in Padove. V Firencah dijaki preživijo tri dni, s poudarkom na ogledih ključnih spomenikov italijanske zgodnje renesanse. Firence so rojstno mesto renesančne umetnosti, in tako imamo na tej ekskurziji prav po dnevih razvrščene oglede muzejev in galerij, kot so Bargello, Uffizi, Medičejska kapela, Accademia, in seveda tudi sakralnih spomenikov, npr. katedrale Santa Maria del Fiore, San Miniata al Monte, Santa Croce, pa tudi kapele Pazzi …
                    </p>
                    <p>
                        Ta ekskurzija je predvidena predvsem za dijake 2. letnikov, s katerimi se že pri pouku vsebinsko pripravljamo, tako sta potem videnje in razumevanje Tizianove Urbinske Venere, Michelangelovega Davida, Urbinskega diptiha, slikarja Piera della Francesce, znamenitih Ghibertijevih Zlatih vrat in vrste drugih izjemnih umetniških stvaritev zagotovo drugačna.
                    </p>
                    <p>
                        Dijaki se na ekskurzijo pripravijo z govornimi nastopi. Vsak udeleženec ekskurzije si izbere želeno umetnino, ki jo predstavi »v živo« v muzeju, galeriji, v mestu … Tako se vsi skupaj učimo, kako vstopati v polje umetniškega dela in s tem tudi umetnika. Učimo se poslušati drug drugega, opazovati, kritično razmišljati. Na tej ekskurziji smo res ves dan obdani z umetninami, njihovo sporočilnostjo.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "dooo",
            category: "",
            title: "Celje in Šempeter",
            pointOfInterest: 120,
            image: "/img/covers/celje-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Že nekaj let zapored na Gimnaziji Ptuj za vse dijake 2. letnikov organiziramo celodnevno ekskurzijo v Šempeter in Celje, s poudarkom na ogledu znamenitosti iz obdobja antike in zgodovine grofov Celjskih. Na poti najprej obiščemo III. mitrej na Ptuju, kjer se dijaki seznanijo s pomenom mitraizma v antični Petovioni, spoznajo vlogo te religije, ki je bila razširjena izključno v moških krogih, in njeno sporočilnost. V  nadaljevanju obiščemo grobnice rimske nekropole v Šempetru.
                    </p>
                    <p>
                        Nato se odpravimo v Celje, kjer si v Knežjem dvoru dijaki ogledajo razstavi Mesto pod mestom in Grofje Celjski. V popoldanskem času se dijaki razdelijo v tri skupine in  samostojno, s pomočjo delovnih listov, spoznavajo znamenitosti Celja. Ob zaključku se odpravimo še na ogled največjega slovenskega srednjeveškega gradu Celje, s katerim tudi zaključimo ekskurzijo.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "dosdo",
            category: "",
            title: "UZG praktikum",
            pointOfInterest: 120,
            image: "/img/covers/uzg-cover.png",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Na Gimnaziji Ptuj si lahko dijaki umetnostno zgodovino izberejo za maturitetni predmet. Umetnostnozgodovinski praktikum predstavlja 20 % notranjega dela izpita. Sestavni del umetnostnozgodovinskega praktikuma je poleg priprave referata v pisni obliki tudi predstavitev teme na terenu. Večinoma si dijaki izbirajo teme, ki so vezane na ogled spomenikov v Ljubljani ter stalnih zbirk Narodne in Moderne galerije. V letošnjem šolskem letu smo eno predstavitev opravili na Ptujski Gori, ostale pa bomo zaradi epidemije Covid-19 opravili večinoma preko videokonference.  Tudi v lanskem šolskem letu smo nekaj predstavitev opravili preko video konference, saj smo morali ekskurzijo v Pariz, ki je tudi  tradicionalna ekskurzija, odpovedati.
                    </p>
                    <img className="fill" src="/img/covers/uzg-inner.png" />
                    <p>
                        Plečnikova hiša v Ljubljani
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "zutt",
            category: "",
            title: "Ekskurzije pri pouku geografije",
            pointOfInterest: 120,
            image: "/img/covers/eks-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Geografija je izbirni maturitetni predmet. Maturanti si pridobijo interno oceno z opravljanjem terenskih vaj in pripravo ter udeležbo na strokovni ekskurziji. V  zadnjih letih smo dijake popeljali na različne konce Slovenije in z njimi odkrivali lepote Krasa in Slovenske Istre, Goričkega, Logarske doline in Bohinja, v preteklem letu pa smo obiskali Koprsko primorje.
                    </p>
                    <img className="fill" src="/img/covers/eks-inner-1.jpg" />
                    <p>
                        Burja na kraškem robu nas je prepihala do kosti.
                    </p>
                    <p>
                        Geografska ekskurzija je poučno, prijetno in nepozabno doživetje tako za dijaka kot učitelja. Dijaki na njej  neposredno in posredno opazujejo, spoznavajo in doživljajo naravne in družbene procese v konkretni pokrajini. Poučni del ekskurzije se prične že na avtobusu, ko dijaki na zemljevidu spremljajo pot, opazujejo okolico, poslušajo razlago in ugotovitve zapisujejo v delovne liste.
                    </p>
                    <img className="fill" src="/img/covers/eks-inner-2.jpg" />
                    <p>
                        Kakšno vreme je danes?  Merjenje vremenskih elementov.
                    </p>
                    <p>
                        Na opazovalnih točkah dijaki s pomočjo mentorjev opravijo terenske vaje, pri katerih se spoznajo s pravilno uporabo preprostih geografskih metod in uporabo različnih pripomočkov za delo na terenu. Tako merijo in vrednotijo vremensko dogajanje, raziskujejo reliefne oblike,  kartirajo dejavnosti v mestu, analizirajo lastnosti morja …
                    </p>
                    <img className="fill" src="/img/covers/eks-inner-3.jpg" />
                    <p>
                        Slanost morja ugodna, temperatura tudi, a za kopanje ni  časa.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "tsutp",
            category: "",
            title: "Narodne izmenjave",
            pointOfInterest: 120,
            image: "/img/covers/nar-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Kje so Rimljani pustili več sledi? V Celei, Poetoviu, Emoni, Caprisu, Colatiu? Kateri grad je veličastnejši? Ljubljanski, škofjeloški, celjski ali ptujski? Kaj imata skupnega vaša in naša gimnazija?  Kaj vaše in naše mesto? Pogosta vprašanja, ki dobijo odgovore na narodnih izmenjavah.
                    </p>
                    <p>
                        Na narodnih izmenjavah dijaki spoznavajo slovenske gimnazije in kraje. Z vrstniki odkrivajo
                        tradicijo, kulturo, jezikovno pestrost in kulinariko krajev, ki so tako blizu doma, a jih je marsikdo na izmenjavi obiskal prvič. Dijaki spoznajo delo in življenje na različnih gimnazijah. Tradicionalno povezovanje se je začelo z evropskimi oddelki in traja na Gimnaziji Ptuj že 17 let. V dosedanjih izmenjavah smo se povezali z Gimnazijo Koper, Gimnazijo Škofja Loka, Gimnazijo Slovenj Gradec, Gimnazijo Lava iz Celja in Gimnazijo Bežigrad iz Ljubljane.
                        Srečanje poteka dva dni. En dan na Ptuju in dan pri vrstnikih.
                    </p>

                    <img className="fill" src="/img/covers/nar-inner-1.jpg" />
                    <img className="fill" src="/img/covers/nar-inner-2.jpg" />
                    <p>
                        Vsaka izmenjava ima določeno skupno temo, ki jo pripravijo dijaki skupaj z razrednikom. Teme so bile športne, naravoslovne, umetniške, jezikovne, dijaki pa so se preizkusili tudi v različnih ročnih spretnosti.
                    </p>
                    <img className="fill" src="/img/covers/nar-inner-3.jpg" />
                    <p>
                        Dragocena pa so tudi nova prijateljstva in  vrstniško odkrivanje mestnih kotičkov, kjer se mladostniki družijo v svojem prostem času.
                    </p>
                    <img className="fill" src="/img/covers/nar-inner-4.jpg" />
                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(5);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan medium"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/AQBXBr288OY?rel=0" />

                    <h1 className="center">Kam pa kam?</h1>
                    <YouTubeEmbed src="https://www.youtube.com/embed/TSxbPz4zBd4?rel=0" />

                    <h1 className="center">Ekskurzije</h1>
                    <ItemGrid items={this.items} />

                </div>
            </>
        );
    }
}