import * as React from 'react';
import { Table } from 'react-bootstrap';
import Carousel from 'react-bootstrap/esm/Carousel';
import { API } from '../../helpers/apiHelper';
import { LiveEvent } from '../../models/liveEvent';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import ItemList, { ItemListItem } from '../animated/itemlist/itemlist';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level3 extends React.PureComponent<{}, { liveEvents: LiveEvent[] }> {
    colorClass: string;
    level: number = 3;
    
    listItems: ItemListItem[] = [
        { key: 1, colorClass: "pink", title: "SJK", content: this.getProfesorVideo("https://www.youtube.com/embed/Am4KPYcG-is?rel=0") },
        { key: 2, colorClass: "lightblue", title: "ŠV", content: this.getProfesorVideo("https://www.youtube.com/embed/cItt_-YHgT4?rel=0") },
        { key: 3, colorClass: "darkblue", title: "BIO", content: this.getProfesorVideo("https://www.youtube.com/embed/B-Rc58M2LyQ?rel=0") },
        { key: 4, colorClass: "lightred", title: "KEM / FIZ", content: this.getProfesorVideo("https://www.youtube.com/embed/6OAi-K4qiaI?rel=0") },
        { key: 5, colorClass: "lightgreen", title: "FIL / SOC / PSI", content: this.getProfesorVideo("https://www.youtube.com/embed/Wze7Qvyw4Bo?rel=0") },
        { key: 6, colorClass: "yellow", title: "Svetovalna služba, knjižnica", content: this.getProfesorVideo("https://www.youtube.com/embed/26EPnXBSrQY?rel=0") },
        { key: 7, colorClass: "lightgreen", title: "MAT / INF", content: this.getProfesorVideo("https://www.youtube.com/embed/QToWz5b9zuQ?rel=0") },
        { key: 8, colorClass: "orange", title: "NEM / FRA / ITA", content: this.getProfesorVideo("https://www.youtube.com/embed/WEpS_IXWqes?rel=0") },
        { key: 9, colorClass: "green", title: "ANG", content: this.getProfesorVideo("https://www.youtube.com/embed/HvfjzNlbsEQ?rel=0") },
        { key: 10, colorClass: "lightyellow", title: "GEO / ZGO", content: this.getProfesorVideo("https://www.youtube.com/embed/ZzlKR5VC6jI?rel=0") },
        { key: 11, colorClass: "purple", title: "UMETNOST / GLASBA", content: this.getProfesorVideo("https://www.youtube.com/embed/FBZtNJo1x-U?rel=0") }
    ];

    items: ItemGridItem[] = [
        {
            id: "f",
            category: "",
            title: "Nadarjeni dijaki",
            pointOfInterest: 120,
            image: "/img/covers/nadarjeni.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>Skrb za nadarjene je sestavni del vizije ter izobraževalnega in razvojnega načrta šole.</p>
                    <p>Med nadarjene dijake ne štejemo le tistih, ki prihajajo k nam s potrdili o nadarjenosti, temveč vse, ki si želijo na Gimnaziji Ptuj razviti svoje potenciale na raznolikih področjih, od tekmovalno raziskovalnih do socialno osebnostih. </p>
                    <p>Ena izmed številnih aktivnosti  je tudi projekt Inkubator rasti. Gre za celoletni program osebnostne rasti v povezavi z nevrolingvističnim programiranjem, ki se mu je priključilo več kot 150 dijakov. Projekt je potekal v okviru nacionalnega projekta RAST.</p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            liveEvents: State.liveEvents
        };

        this.colorClass = State.getClassForLevel(3);

        if (State.liveEvents.length === 0) {
            API.getLiveEvents().then(l => {
                this.setState({
                    liveEvents: l
                });

                State.liveEvents = l;
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/9tMun2-ylvk?rel=0" />

                    <h1>Pouk v živo</h1>
                    <div className="fancyBox">
                        <p>Tabela dogodkov:</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Čas</th>
                                    <th>Dogodek</th>
                                    <th>Povezava</th>
                                    <th>Živo?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.liveEvents.map(live => (
                                    <tr key={live.id}>
                                        <td>{live.startTime}</td>
                                        <td>{live.caption}</td>
                                        <td><a href={live.link}>{live.link}</a></td>
                                        <td>{live.isLive ? "DA" : "NE"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <h1 className="center">Knjižnica</h1>
                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/knjiznica2/0.jpg",
                                    "/img/sliders/knjiznica2/1.jpg",
                                    "/img/sliders/knjiznica2/2.jpg",
                                    "/img/sliders/knjiznica2/3.jpg",
                                    "/img/sliders/knjiznica2/4.jpg",
                                    "/img/sliders/knjiznica2/5.jpg",
                                    "/img/sliders/knjiznica2/6.jpg",
                                    "/img/sliders/knjiznica2/7.jpg",
                                    "/img/sliders/knjiznica2/8.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <br />
                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/sss/0.jpg",
                                    "/img/sliders/sss/1.jpg",
                                    "/img/sliders/sss/2.jpg",
                                    "/img/sliders/sss/3.jpg",
                                    "/img/sliders/sss/4.jpg",
                                    "/img/sliders/sss/5.jpg",
                                    "/img/sliders/sss/6.jpg",
                                    "/img/sliders/sss/7.jpg",
                                    "/img/sliders/sss/8.jpg",
                                    "/img/sliders/sss/9.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <br />
                    <ItemGrid items={this.items} />

                    <h1>Profesorji</h1>
                    <ItemList items={this.listItems} />

                    <h1 className="center">Pouk na daljavo</h1><br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/X1ycCHZVjgs?rel=0" />
                    
                </div>
            </>
        );
    }

    getProfesorVideo(link: string): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <YouTubeEmbed src={link} />
                </div>
            </>
        );
    }

}