import axios, { AxiosResponse } from "axios";
import { stat } from "fs";
import { ContentValue } from "../models/contentValue";
import { ExportedForms } from "../models/exportedForm";
import { LevelData } from "../models/levelData";
import { LiveEvent } from "../models/liveEvent";
import { LoginResponse } from "../models/loginResponse";
import { RecentLog } from "../models/recentLog";
import { RequestStat } from "../models/requestStat";
import { SubmittedFormData } from "../models/submittedFormData";
import { State } from "../state";

class ApiHelper {
    getHeaders(): any {
        var headers: any = {
            "Client": "InfoDan-GimPtuj-1"
        };

        if (State.isLoggedIn()) {
            //@ts-ignore
            headers.Authorization = "Bearer " + State.login.token;
        }

        return {
            headers
        }
    }

    public async getForms(page: number = 0): Promise<SubmittedFormData[]> {
        try {
            const response = await axios.get(`/api/form/${page}`, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful form list fetch!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to fetch form list!", error);
            return [];
        }
    }

    public async deleteForm(id: number): Promise<number> {
        try {
            const response = await axios.delete(`/api/form/${id}`, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful form delete!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);
            console.error("Failed to delete form!", error);
            return 0;
        }
    }

    public async getLiveEvents(): Promise<LiveEvent[]> {
        try {
            const response = await axios.get(`/api/content/live`, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful live event list fetch!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to fetch live event list!", error);
            return [];
        }
    }

    public async deleteLiveEvent(id: number): Promise<number> {
        try {
            const response = await axios.delete(`/api/content/live/${id}`, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful live event delete!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);
            console.error("Failed to delete live event!", error);
            return 0;
        }
    }

    public async createOrUpdateLiveEvent(id: number | null, liveEvent: LiveEvent): Promise<number | LiveEvent> {
        try {
            const response = await axios.post("/api/content/live" + (id === null ? "" : `/${id}`), {
                isLive: liveEvent.isLive,
                caption: liveEvent.caption,
                time: liveEvent.startTime,
                link: liveEvent.link
            }, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful live event create/update!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);
            console.error("Failed to create/update live event!", error);
            return 0;
        }
    }

    public async getLevels(): Promise<LevelData[]> {
        try {
            const response = await axios.get("/api/level/list", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful level list fetch!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            console.error("Failed to fetch level list!", error);
            return [];
        }
    }

    public async getLevelsDetails(): Promise<LevelData[]> {
        try {
            const response = await axios.get("/api/level", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful level details list fetch!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to fetch level details list!", error);
            return [];
        }
    }

    public async setLevelPassword(id: number, password: string): Promise<number> {
        try {
            const response = await axios.post("/api/level/" + id, {
                password
            }, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful level password update!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to set level password!", error);
            return 0;
        }
    }

    public async unlockLevel(password: string, originLevelIndex: number, currentlevelIndex: number): Promise<LevelData | null | boolean> {
        try {
            const response = await axios.post("/api/level", {
                password,
                originLevelIndex,
                currentlevelIndex
            }, this.getHeaders());

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful level unlock!", response.data.data);
                return null;
            }
            else return response.data.data;
        }
        catch (error) {
            if (error.response) {
                const status: number = error.response.status;
                if (status === 429) return false;
            }

            console.error("Failed to unlock level!", error);
            return null;
        }
    }

    public async login(user: string, password: string): Promise<LoginResponse | string> {
        try {
            var response = await axios.post("/api/admin/login", {
                user,
                password
            }, this.getHeaders());

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful login!", response.data.data);
                return response.data.data;
            }
            else return response.data.data;
        }
        catch (error) {
            console.error("Failed to login!", error);

            if (error && error.response && error.response.data) {
                if (error.response.data.data === undefined) return "Invalid credentials";
                else return error.response.data.data;
            }
            else {
                return "Server error";
            }
        }
    }

    public async formSave(elementarySchool: string | null, otherCandidateSchool: string | null,
        program: number | null, certainty: number | null, rating: number | null, classNumber: number | null): Promise<boolean | string> {

        try {
            var response = await axios.post("/api/form", {
                elementarySchool,
                otherCandidateSchool,
                program,
                certainty,
                rating,
                classNumber
            }, this.getHeaders());

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful form upload!", response.data.data);
                return response.data.data;
            }
            else return true;
        }
        catch (error) {
            console.error("Failed to upload form!", error);

            if (error && error.response && error.response.data) {
                if (error.response.data.data === undefined) return "Unknown server error";
                else return error.response.data.data;
            }
            else {
                return "Server error";
            }
        }
    }

    public async logSession(id: string): Promise<number> {
        try {
            const response = await axios.post("/api/admin/stats/log", {
                id
            }, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful session log!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            console.error("Failed to log session!", error);
            return 0;
        }
    }

    public async getSessionCount(): Promise<number> {
        try {
            const response = await axios.get("/api/admin/stats/sessions", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful session count get!", response.data.data);
                return 0;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to get session count!", error);
            return 0;
        }
    }

    public async getRecentLogs(): Promise<RecentLog[]> {
        try {
            const response = await axios.get("/api/admin/stats/logs", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful recent log list get!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to get recent log list!", error);
            return [];
        }
    }

    public async exportFormsToCSV(): Promise<ExportedForms | null> {
        try {
            const response = await axios.get("/api/form/csv", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful form export!", response.data.data);
                return null;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to export form!", error);
            return null;
        }
    }

    public async getRequestStats(): Promise<RequestStat[]> {
        try {
            const response = await axios.get("/api/admin/stats/requests", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful request stats get!", response.data.data);
                return [];
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to get request stats!", error);
            return [];
        }
    }

    public async getCountdown(): Promise<ContentValue | null> {
        try {
            // 05/03/2021 00:04:41
            const response = await axios.get("/api/content/countdown", this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful countdown get!", response.data.data);
                return null;
            }
            else return response.data.data;
        }
        catch (error) {
            console.error("Failed to get countdown!", error);
            return null;
        }
    }

    public async setCountdown(content: string): Promise<ContentValue | null> {
        try {
            const response = await axios.post("/api/content/countdown", { content }, this.getHeaders())

            const success: boolean = response.data.success;
            if (!success) {
                console.error("Unsuccessful countdown set!", response.data.data);
                return null;
            }
            else return response.data.data;
        }
        catch (error) {
            this.checkStatusForLogout(error);

            console.error("Failed to set countdown!", error);
            return null;
        }
    }

    checkStatusForLogout(error: any) {

        if (error.response) {
            const status: number = error.response.status;
            if (status == 401) {
                // unauthorized, log out!
                State.resetLogin();
                window.location.pathname = "/login";
            }
        }
    }
}

export const API = new ApiHelper();