import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import { State } from '../../state';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level10 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 10;

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(10);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/99RXRS9tcn4?rel=0" />

                    <h1 className="center">Dijaška skupnost</h1>
                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    ["/img/sliders/dijaska/0.jpg"],
                                    ["/img/sliders/dijaska/1.jpg"],
                                    ["/img/sliders/dijaska/2.jpg"],
                                    ["/img/sliders/dijaska/3.jpg"],
                                    ["/img/sliders/dijaska/4.jpg"],
                                    ["/img/sliders/dijaska/5.jpg"],
                                    ["/img/sliders/dijaska/6.jpg"],
                                    ["/img/sliders/dijaska/7.jpg"],
                                    ["/img/sliders/dijaska/8.jpg"],
                                ].map(link => {
                                    const ky: any = link[0];
                                    const el: any = link[1];

                                    return (
                                        <Carousel.Item key={ky}>
                                            <img
                                                className="d-block w-100"
                                                src={ky}
                                            />
                                            <Carousel.Caption>
                                                {el}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </div>

                    <br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/Owp3myBdgnU?rel=0" />
                </div>
            </>
        );
    }
}