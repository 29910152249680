import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { State } from '../../state';

export default class GuardedRoute extends React.PureComponent<{
    onLevelChanged: any,
    levelNumber: number,
    component: any,
    path: string
}> {
    public render() {
        return (
            <Route path={this.props.path} render={() => {             
                if (State.currentLevel >= this.props.levelNumber) {
                    if (State.selectedLevel != this.props.levelNumber) {
                        this.props.onLevelChanged(this.props.levelNumber);
                    }

                    return <this.props.component />;
                }
                else
                {
                    return <Redirect to={{ pathname: "/locked" }} />
                }
            }} />     
        );
    }
}
