import * as React from 'react';
import { Link } from 'react-router-dom';
import { State } from '../../state';

export default class LevelBox extends React.PureComponent<{ onLevelChanged: (i: number) => void, levelNumber: number, caption: string }> {
    public render() {
        return (
            <Link to={State.getRedirectForLevel(this.props.levelNumber)} className={`levelBox ${State.getClassForLevel(this.props.levelNumber)} 
                ${State.selectedLevel == this.props.levelNumber ? "selected" : ""} ${State.currentLevel < this.props.levelNumber ? "locked" : ""}`}
                onClick={() => this.props.onLevelChanged(this.props.levelNumber)}>

                <div className="level-name">KORAK {this.props.levelNumber}</div>
                <div className="level-caption">{this.props.caption}</div>
            </Link>      
        );
    }
}
