import { log } from "util";
import { LoginResponse } from "./models/loginResponse";
import gimptujLogoDark from './images/gimptuj-dark.png';
import gimptujLogoWhite from './images/gimptuj-white.png';
import slogan0 from './images/slogans/zgrabi-prihodnost.png'
import slogan1 from './images/slogans/zgrabi-prihodnost.png';
import slogan2 from './images/slogans/white/ze-imas-svoj-stil.png';
import slogan3 from './images/slogans/white/vec-znas-vec-veljas.png';
import slogan4 from './images/slogans/white/izbirati-izbrati.png';
import slogan5 from './images/slogans/white/vzhod-zahod-sever-ali-jug.png';
import slogan6 from './images/slogans/white/seife-savon-shampoo.png';
import slogan7 from './images/slogans/white/zvezde.png';
import slogan8 from './images/slogans/white/2-krat-s.png';
import slogan9 from './images/slogans/white/umetniki-2.png';
import slogan10 from './images/slogans/white/sanjati-ali-spati.png';
import slogan11 from './images/slogans/white/zaspana-prva-ali-utrujena-zadnja.png';
import slogan12 from './images/slogans/white/sem-na-pravi-poti.png';
import { API } from "./helpers/apiHelper";
import { LiveEvent } from "./models/liveEvent";

class StateData {
    public login: LoginResponse | null = null;
    public sessionId: string = "";
    public formSaved: number = 0;
    public currentLevel: number = 0;
    public selectedLevel: number = 0;
    public liveEvents: LiveEvent[] = [];

    public setLevel(newLevel: number) {
        // save current level
        this.currentLevel = newLevel;
        var toEncode = Math.log(this.currentLevel) * 10;
        localStorage.setItem("clv", this.encode(toEncode.toString()));
    }

    public loadData() {
        // load current level
        const current_level_decoded = this.decode(localStorage.getItem("clv"));
        if (current_level_decoded === null) this.setLevel(0);
        else {
            const decoded_val = Math.pow(Math.E, +current_level_decoded / 10.0);
            const rounded = Math.round(decoded_val);
            const diff = Math.abs(rounded - decoded_val);
            if (diff > 0.01) {
                console.error("Invalid local storage state! Reseting progress...");
                this.setLevel(0);
            }
            else {
                this.setLevel(rounded);
            }
        }
        console.log("Currently on level " + this.currentLevel);

        // load isLoggedIn
        this.loadLoggedIn();

        // load form saved
        this.loadFormSaved();

        // load session id
        this.loadSessionId();
        API.logSession(this.sessionId);
    }

    encode(value: string): string {
        const msg = value;

        // encode
        var base64 = btoa(msg)
        return base64;
    }

    decode(valueEncoded: string | null): string | null {
        if (valueEncoded == null) return null;

        try {
            // decode
            var msg = atob(valueEncoded);
            return msg;
        }
        catch {
            return null;
        }
    }

    loadLoggedIn() {
        // load token from localstorage
        const tkn = this.decode(localStorage.getItem("tkn"));
        if (tkn !== null && typeof tkn == "string" && tkn.length > 2) {
            console.log("Recovered login session");
            this.login = {
                token: tkn
            };
        }
    }

    loadSessionId() {
        this.sessionId = localStorage.getItem("sid") ?? "";
        if (!this.sessionId || this.sessionId.length <= 2) {
            // generate new one
            this.sessionId = this.makeid(64);  
            localStorage.setItem("sid", this.sessionId);
        }
    }

    makeid(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    loadFormSaved() {
        const tkn = localStorage.getItem("frm");
        if (tkn === "1") {
            console.log("Recovered form state");
            this.formSaved = 1;
        }
        else {
            this.formSaved = 0;
        }
    }

    public saveForm() {
        this.formSaved = 1;
        localStorage.setItem("frm", "1");
    }

    public resetLogin() {
        this.login = null;
        localStorage.setItem("tkn", "");
    }

    public setLogin(login: LoginResponse) {
        this.login = login;

        // save to localstorage
        localStorage.setItem("tkn", this.encode(login.token));
    }

    public isLoggedIn(): boolean {
        if (this.login == null) return false;

        // TODO: check expiry date or something

        return true;
    }

    public isOnMainPage(): boolean {
        var path = window.location.pathname.toLowerCase();

        if (path.startsWith("/login") || path.startsWith("/dashboard")) return false;

        return true;
    }

    public getClassForLevel(level: number): string {
        switch (level) {
            case 0:
            case 13: return "yellow";
            case 1: return "lightyellow";
            case 2: return "lightgreen";
            case 3: return "lightblue";
            case 4: return "orange";
            case 5: return "green";
            case 6: return "lightred";
            case 7: return "pink";
            case 8: return "darkblue";
            case 9: return "grayblue";
            case 10: return "purple";
            case 11: return "lightpurple";
            case 12: return "lightorange";
            default: return "";
        }
    }

    public getRedirectForLevel(level: number): string {
        switch (level) {
            case 0: return "/";
            case 1: return "/level1";
            case 2: return "/level2";
            case 3: return "/level3";
            case 4: return "/level4";
            case 5: return "/level5";
            case 6: return "/level6";
            case 7: return "/level7";
            case 8: return "/level8";
            case 9: return "/level9";
            case 10: return "/level10";
            case 11: return "/level11";
            case 12: return "/level12";
            case 13: return "/level13";
            default: return "/";
        }
    }

    public getSloganForLevel(level: number): string | undefined {
        switch (level) {
            case 0: return slogan0;
            case 1: return slogan1;
            case 2: return slogan2;
            case 3: return slogan3;
            case 4: return slogan4;
            case 5: return slogan5;
            case 6: return slogan6;
            case 7: return slogan7;
            case 8: return slogan8;
            case 9: return slogan9;
            case 10: return slogan10;
            case 11: return slogan11;
            case 12: return slogan12;
            default: return undefined;
        }
    }

    public getGimptujImageWhite(): string {
        return gimptujLogoWhite;
    }

    public getGimptujImageDark(): string {
        return gimptujLogoDark;
    }
}

export const State = new StateData();