export class SubmittedFormData {
    id: number = 0;
    ip: string = "";
    created: Date = new Date();
    elementarySchool: string | null = null;
    otherSchool: string | null = null;
    rating: number = 0;
    certainty: number = 0;
    program: number = 0;
    class: number = 0;
}