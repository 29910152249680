import * as React from 'react';
import "./YoutubeEmbed.css";

export default class YouTubeEmbed extends React.PureComponent<{ src: string }> {
    public render() {
        return (
            <div className="video-container">
                <div className="video-wrapper-1">
                    <div className="video-wrapper-2">
                        <iframe width="100%" height="100%" src={this.props.src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        );
    }
}
