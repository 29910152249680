import * as React from 'react';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import ItemList, { ItemListItem } from '../animated/itemlist/itemlist';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level7 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 7;

    items: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Naši olimpijci",
            pointOfInterest: 80,
            image: "/img/covers/olimpijci-cover.jpg",
            backgroundColor: "#814A0E",
            content: (
                <div>
                    <p><b>MATEMATIKA</b></p>

                    <b className="whitetxt">Gal Zmazek</b>
                    <p>Srednjeevropska matematična olimpijada 2019<br />Pardubice, Češka (srebrna medalja, 13. mesto)</p>
                    <p>Balkanska fizikalna olimpijada 2019<br />Solun, Grčija (bronasta medalja)</p>
                    <p>Sredozemsko matematično tekmovanje 2019<br />Ljubljana, Slovenija (bronasta medalja)</p>
                    <p>Študentsko tekmovanje VJIMC  2019<br />Ostrava, Češka</p>
                    <p>Srednjeevropska matematična olimpijada 2020<br />Zürich, Švica (pohvala, 31. mesto)</p>
                    <p>Cyberspace Mathematical Competition 2020<br />Slovenija (pohvala)</p>
                    <img className="fill limit310" src="/img/covers/olimp-0.jpg" />

                    <br /><br />
                    <p><b>GEOGRAFIJA</b></p>

                    <b className="whitetxt">Blaž Murko</b>
                    <p>Svetovna geografska olimpijada 2016<br />Peking, Kitajska  (bronasta medalja)</p>
                    <img className="fill limit310" src="/img/covers/olimp-1.jpg" />

                    <br /><br />
                    <b className="whitetxt">Žan Malek Petrovič</b>
                    <p>Balkanska geografska olimpijada 2015<br />Beograd, Srbija ( srebrna medalja)</p>
                    <img className="fill limit310" src="/img/covers/olimp-2.jpg" />

                    <br /><br />
                    <p><b>KEMIJA</b></p>

                    <b className="whitetxt">Luka Pušnik</b>
                    <p>47. Kemijska olimpijada 2015<br />Baku, Azerbajdžan </p>
                    <img className="fill limit310" src="/img/covers/olimp-3.jpg" />

                    <br /><br />
                    <b className="whitetxt">Mitja Koderman</b>
                    <p>51. Kemijska olimpijada 2019<br />Pariz, Francija</p>
                    <img className="fill limit310" src="/img/covers/olimp-4.jpg" />

                    <br /><br />
                    <p><b>ATLETIKA, SKOK V DALJINO</b></p>

                    <b className="whitetxt">Nina Kolarič</b>
                    <p>Olimpijske igre 2008<br />Peking, Kitajska</p>
                    <img className="fill limit310" src="/img/covers/olimp-5.jpg" />

                    <br /><br />
                    <p><b>FIZIKA</b></p>

                    <b className="whitetxt">Primož Kušar</b>
                    <p>Fizikalna olimpijada 1994<br />Peking, Kitajska</p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "f",
            category: "",
            title: "Uspehi na tekmovanjih",
            pointOfInterest: 120,
            image: "/img/covers/olimpijci-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div className="graytxt">
                    <p><b>Šolsko leto 2020/2021</b></p>

                    ZLATA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Lara Kolar</li>
                        <li><b>razvedrilna matematika:</b> Barbara Kropec, Luka Hodnik</li>
                        <li><b>matematika:</b> Gal Zmazek</li>
                        <li><b>logika:</b> Gašper Vrtačnik</li>
                        <li><b>biologija:</b> Matija Berden Strelec</li>
                        <li><b>fizika:</b> Matija Berden Strelec</li>
                        <li><b>kemija:</b> Matija Berden Strelec</li>
                        <li><b>angleščina (video):</b> Miha Godicelj, Rok Fakin, Filip Verdenik, Jan Miklošič, Nino Šegula</li>
                        <li><b>angleščina za 3. letnike:</b> Matija Peršak</li>
                        <li><b>sladkorna bolezen:</b> Matija Berden Strelec, Manja Dokl</li>
                    </ul>
                    <br />

                    SREBRNA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Sandro Čeh, Gaja Železnik, Vanessa Repič, Vida Glatz, Rebeka Pernat, Manja Dokl</li>
                        <li><b>razvedrilna matematika:</b> Sandro Čeh, Maja Leskovar, Gal Zmazek</li>
                        <li><b>matematika:</b> Barbara Kropec, Aljaž Habjanec, Luka Hodnik, Maja Leskovar, Lovro Lozinšek, Žiga Kovačič</li>
                        <li><b>logika:</b> Barbara Kropec, Luka Hodnik, Gal Zmazek</li>
                        <li><b>biologija:</b> Sandro Čeh, Eva Žunkovič</li>
                        <li><b>fizika:</b> Matej Kolednik, Maja Leskovar, Gal Zmazek</li>
                        <li><b>kemija:</b> Tin Šoškič, Gal Zmazek</li>
                        <li><b>sladkorna bolezen:</b> Pia Kramberger</li>
                    </ul>
                    <br />

                    <p><b>Šolsko leto 2019/2020</b></p>

                    ZLATA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Vida Glatz, Manja Dokl</li>
                        <li><b>razvedrilna matematika:</b> Luka Hodnik, Gašper Vrtačnik</li>
                        <li><b>nemščina:</b> Špela Kukovec (2. mesto),  Sara Rosalia Gutschi</li>
                        <li><b>logika:</b> Luka Hodnik, Bono Bezjak, Gašper Vrtačnik</li>
                        <li><b>sladkorna bolezen:</b> Manja Dokl, Vida Glatz</li>
                    </ul>
                    <br />

                    SREBRNA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Barbara Tement, Eva Žunkovič, Vida Glatz, Manja Dokl, Liza Likar, Matija Peršak, Gloria Šegula Šraj</li>
                        <li><b>razvedrilna matematika:</b> Maja Leskovar, Gal Zmazek</li>
                        <li><b>nemščina:</b> Sara Sakelšek</li>
                        <li><b>logika:</b> Zala Cebek, Lovro Lozinšek, Miha Mesarič, Vida Glatz</li>
                        <li><b>geografija:</b> Pia Kramberger, Matija Berden Strelec, Ambrož Rodošek</li>
                        <li><b>sladkorna bolezen:</b> Matija Berden Strelec</li>
                    </ul>
                    <br />

                    NATEČAJI IN RAZISKOVALNE NALOGE
                    <ul className="normal">
                        <li>zlato regijsko priznanje za raziskovalno nalogo iz kemije: Alja Rozman in Mateja Golc</li>
                    </ul>
                    <br />


                    <p><b>Šolsko leto 2018/2019</b></p>


                    ZLATA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Vida Glatz, Manja Dokl, Julija Menoni, Let Lovse</li>
                        <li><b>matematika:</b> Maja Leskovar, Gal Zmazek</li>
                        <li><b>matemček:</b> Gal Zmazek (1. mesto)</li>
                        <li><b>razvedrilna matematika:</b> Gal Zmazek (vse možne točke), Manca Gavez, Maja Leskovar</li>
                        <li><b>angleščina:</b> Liza Likar</li>
                        <li><b>nemščina:</b> Sara Rosalia Gutschi (1. mesto)</li>
                        <li><b>zgodovina:</b> Tea Korošec (1. mesto), Žiga Ciglarič (2. mesto)</li>
                        <li><b>geografija:</b> Tea Korošec</li>
                        <li><b>biologija:</b> Matija Berden Strelec in Alja Rozman</li>
                        <li><b>kemija:</b> Matija Berden Strelec, Mitja Koderman (1. mesto)</li>
                    </ul>
                    <br />


                    SREBRNA PRIZNANJA
                    <ul className="normal">
                        <li><b>slovenščina:</b> Vida Glatz, Matija Peršak, Manja Dokl, Monja Sabotin, Meta Majcen, Uroš Ber, Mia Molnar, Vid Buzeti, Maša Sarić, Let Lovse, Julija Menoni, Vita Pernat, Mitja Koderman</li>
                        <li><b>matematika:</b> Luka Černesl, Lan Trafela, Mia Molnar</li>
                        <li><b>razvedrilna matematika:</b> Luka Černesl</li>
                        <li><b>logika:</b> Vida Glatz, Gal Zmazek</li>
                        <li><b>nemščina:</b> Špela Kukovec, Tajda Bratec, Lara Sakelšek</li>
                        <li><b>zgodovina:</b> Tomaž Horvat</li>
                        <li><b>biologija:</b> Lucija Jančič</li>
                        <li><b>fizika:</b> Gal Zmazek, Jan Fakin</li>
                        <li><b>astronomija:</b> Mitja Koderman, Vida Glatz</li>
                        <li><b>filozofija:</b> Tilen Milošič</li>
                        <li><b>sladkorna bolezen:</b> Manja Dokl, Vida Glatz, Sanja Kostevc</li>
                    </ul>
                    <br />


                    NATEČAJI IN RAZISKOVALNE NALOGE
                    <ul className="normal">
                        <li>zlato regijsko priznanje za raziskovalno nalogo iz kemije: Lucija Jančič, Lara Roškar, Tomaž Horvat</li>
                        <li>pohvala za najboljši izdelek na natečaju Periodni sistem elementov – včeraj, danes, jutri : Polonca Škerget, Vida Glatz, Nika Keres</li>
                        <li>srebrno priznanje na tekmovanju Slovenščina ima dolg jezik : Lara Roškar, Sara Glatz, Filip Zelenjak, Tomaž Horvat, Sara Serdinšek</li>
                        <li>najboljša haikuja v kategorijah s poetološko in bivanjsko tematiko na Srednješolskem tekmovanju za najboljši haiku: Neja Veit in Alja Rozman</li>
                        <li>prvo mesto v državi na literarnem natečaju Evropa v šoli:  Mateja Golc</li>
                    </ul>

                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    listItems: ItemListItem[] = [
        { key: 1, colorClass: "yellow", title: "Tadej Toš", content: this.getProfesorVideo("https://www.youtube.com/embed/9caAeVgWCO8?rel=0") },
        { key: 2, colorClass: "pink", title: "Žan Tetičkovič", content: this.getProfesorVideo("https://www.youtube.com/embed/6rQ0q5X8JVQ?rel=0") },
        { key: 3, colorClass: "lightgreen", title: "Polona Fijavž", content: this.getProfesorVideo("https://www.youtube.com/embed/NWNm2SRyRGk?rel=0") },
        { key: 4, colorClass: "lightblue", title: "Marko Drobnič", content: this.getProfesorVideo("https://www.youtube.com/embed/v8-I3-Qhc3o?rel=0") },
        { key: 5, colorClass: "orange", title: "Veronika Domjan", content: this.getProfesorVideo("https://www.youtube.com/embed/-bXsGhIuz5s?rel=0") },
        { key: 6, colorClass: "green", title: "Nuška Gajšek", content: this.getProfesorVideo("https://www.youtube.com/embed/bdXA3xGwEuE?rel=0") },
        { key: 7, colorClass: "lightred", title: "Miha Zupanič", content: this.getProfesorVideo("https://www.youtube.com/embed/SmeHJ2ForqQ?rel=0") },
        { key: 8, colorClass: "lightyellow", title: "Miha Andrič", content: this.getProfesorVideo("https://www.youtube.com/embed/qGHxG0tWqM4?rel=0") },
        { key: 9, colorClass: "darkblue", title: "Jurček K. Lesjak", content: this.getProfesorVideo("https://www.youtube.com/embed/urV1wZQGRsg?rel=0") },
        { key: 10, colorClass: "grayblue", title: "Janja Hojnik", content: this.getProfesorVideo("https://www.youtube.com/embed/LKXFsP5SPbs?rel=0") },
        { key: 11, colorClass: "purple", title: "Blaž Rola", content: this.getProfesorVideo("https://www.youtube.com/embed/t3mjGI_TsR8?rel=0") }
    ];

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(7);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    getProfesorVideo(link: string): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <YouTubeEmbed src={link} />
                </div>
            </>
        );
    }

    readMore() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <>
                        <p className="lessMargin"><b> ZLATI MATURANTI  2016 • 2021</b></p>
                        <p className="lessMargin">Liza Likar • Tomaž Horvat • Tea Korošec • Julija Menoni</p>
                        <p className="lessMargin">Vita Pernat  • Mitja Koderman • Tilen Kropfl • Jana Meško</p>
                        <p className="lessMargin">Tilen Kolar • Katarina Horvat • Manja Toplak • Petra Toplak</p>
                        <p className="lessMargin">Jure Glodež • Eva Vidovič • Jaka Horvat • Špela Kukovec</p>
                        <p>Gal Zmazek • Matej Kolednik • Zala Veselič</p>

                        <p className="lessMargin"><b> DIJAKI GENERACIJ  2016 • 2021</b></p>
                        <p className="lessMargin">Urška Kiselak • Katarina Vaupotič • Julija Menoni </p>
                        <p className="lessMargin">Mitja Koderman • Tilen Kolar • Taja Islamovič • Lucija Sevšek </p>
                        <p>Žan Malek Petrovič • Tomi Petek • Vida Glatz • Gal Zmazek</p>
                    </>
                )}
            </>
        );
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan zvezdeSlogan large"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/4pkw4-U1Vwo?rel=0" />

                    <h1>Naši uspehi</h1>
                    <div className="fancyBox">
                        <p><b>ZLATI MATURANTI in DIJAKI GENERACIJ  2016 • 2021</b></p>
                        <this.readMore />
                    </div>

                    <h1>Znani gimnazijci</h1>
                    <ItemList items={this.listItems} />

                    <br />
                    <ItemGrid items={this.items} />
                </div>
            </>
        );
    }
}