
import * as React from 'react';
import './admin.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import LogClient from '../../signalr/logClient';
import { Utility } from '../../helpers/utility';
import { API } from '../../helpers/apiHelper';
import { SubmittedFormData } from '../../models/submittedFormData';
import { LevelData } from '../../models/levelData';
import { State } from '../../state';
import { RequestStat } from '../../models/requestStat';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { LiveEvent } from '../../models/liveEvent';

export default class Dashboard extends React.PureComponent<{}, {
    sessionCount: number,
    forms: SubmittedFormData[],
    logs: { key: string, time: string, message: string, type: number }[],
    levels: LevelData[],
    reqStats: RequestStat[],
    exportingForms: boolean,
    levelUpdating: boolean,
    masterPasswordRevealed: boolean,
    liveEvents: LiveEvent[]
}> {
    logClient: LogClient | null = null;
    formLimit: number = 500;

    constructor(props: any) {
        super(props);

        this.getTraffic = this.getTraffic.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.getUniqueIPs = this.getUniqueIPs.bind(this);
        this.levelPasswordChange = this.levelPasswordChange.bind(this);
        this.masterPasswordChange = this.masterPasswordChange.bind(this);

        this.state = {
            sessionCount: 1,
            forms: [],
            logs: [],
            levels: [],
            reqStats: [],
            exportingForms: false,
            levelUpdating: false,
            masterPasswordRevealed: false,
            liveEvents: []
        };

        this.setupLogHub();

        // get initial data
        API.getSessionCount().then(n => {
            this.setState({
                sessionCount: n
            });
        });
        API.getForms().then(forms => {
            this.setState({
                forms: forms.map(frm => {
                    const f = new SubmittedFormData();
                    f.id = frm.id;
                    f.ip = frm.ip;
                    f.created = Utility.getLocalizedDate(frm.created);
                    f.elementarySchool = frm.elementarySchool;
                    f.otherSchool = frm.otherSchool;
                    f.rating = frm.rating;
                    f.certainty = frm.certainty;
                    f.program = frm.program;
                    f.class = frm.class;

                    return f;
                })
            });
        });
        API.getLevelsDetails().then(levels => {
            this.setState({
                levels: levels
            });
        });
        API.getRecentLogs().then(logs => {
            this.setState({
                logs: logs.map((x, index) => ({
                    key: index + State.makeid(24),
                    message: x.message,
                    time: Utility.getLocalizedTimeDateForm(x.time),
                    type: x.type
                }))
            });
        });
        API.getCountdown().then(c => {
            if (c == null) {
                this.setState({
                    masterPasswordRevealed: false
                });
            }
            else {
                this.setState({
                    masterPasswordRevealed: c.password.length > 0
                });
            }
        });
        API.getLiveEvents().then(l => {
            this.setState({
                liveEvents: l
            });
        })

        // put this one in a loop (pooling)
        this.refreshRequestStats();
        setInterval(async () => {
            await this.refreshRequestStats();
        }, 1_000 * 60 * 15);
    }

    setupLogHub() {
        const logCallback = (log: string, type: number) => {
            const now = Utility.getCurrentTimeDateForm();

            const num = this.state.logs.length + State.makeid(24);

            // let's limit displayed logs
            while (this.state.logs.length > 2000) this.state.logs.shift();

            this.setState({
                logs: this.state.logs.concat([{ key: num, time: now, message: log, type: type }])
            });
        };

        const formCallback = (id: number, ip: string, eSchool: string, oSchool: string, rating: number, certainty: number, program: number, classNumber: number) => {
            const f = new SubmittedFormData();
            f.id = id;
            f.ip = ip;
            f.elementarySchool = eSchool;
            f.otherSchool = oSchool;
            f.rating = rating;
            f.created = new Date();
            f.certainty = certainty;
            f.program = program;
            f.class = classNumber;

            // let's limit displayed forms
            while (this.state.forms.length > this.formLimit) this.state.forms.pop();

            this.state.forms.unshift(f);
            this.setState({
                forms: this.state.forms.slice(0)
            });
        };

        const sessionCountCallback = (count: number) => {
            this.setState({
                sessionCount: count
            });
        };

        this.logClient = new LogClient(logCallback, formCallback, sessionCountCallback);
    }

    async refreshRequestStats(): Promise<void> {
        const stats = await API.getRequestStats();

        this.setState({
            reqStats: stats
        })
    }

    getTraffic() {
        const data = this.state.reqStats.map(v => ({
            name: Utility.getShortDate(v.day ?? new Date()),
            Count: v.reqCount
        }));

        return (
            <>
                <div>Request traffic in past 30 days</div>
                <ResponsiveContainer height={400} width='100%'>
                    <AreaChart data={data}
                        margin={{
                            top: 10, right: 30, left: 0, bottom: 0,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="Count" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                </ResponsiveContainer>
            </>
        );
    }

    getUniqueIPs() {
        const data = this.state.reqStats.map(v => ({
            name: Utility.getShortDate(v.day ?? new Date()),
            Count: v.reqCountUnique
        }));

        return (
            <>
                <div>Unique IP access in last 30 days:</div>
                <ResponsiveContainer height={400} width='100%'>
                    <AreaChart data={data}
                        margin={{
                            top: 10, right: 30, left: 0, bottom: 0,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="Count" stroke="#8884d8" fill="#c463c7" />
                    </AreaChart>
                </ResponsiveContainer>
            </>
        );
    }

    async downloadCSV() {
        this.setState({ exportingForms: true });
        const exported = await API.exportFormsToCSV();
        this.setState({ exportingForms: false });
        if (exported == null) {
            alert("Failed to export to CSV!");
            return;
        }

        const element = document.createElement("a");
        const file = new Blob([exported?.csv], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "exported.csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    levelPasswordChange(e: any, level: LevelData) {
        const newPassword: string = e.target.value;

        level.password = newPassword.toLowerCase().trim().replace("č", "c").replace("š", "s").replace("ž", "z");

        this.setState({
            levels: this.state.levels.slice(0)
        })
    }

    async updatePassword(level: LevelData) {
        this.setState({ levelUpdating: true });
        var editedCount = await API.setLevelPassword(level.id, level.password);
        this.setState({ levelUpdating: false });

        if (editedCount === 0) {
            alert("Failed to update level!");
            return;
        }
    }

    async saveLive(live: LiveEvent) {
        this.setState({ levelUpdating: true });
        var editedCount = await API.createOrUpdateLiveEvent(live.id, live);
        this.setState({ levelUpdating: false });

        if (editedCount === 0) {
            alert("Failed to update live event!");
            return;
        }
    }

    async deleteLive(live: LiveEvent) {
        if (window.confirm("Are you sure to delete this live event?") !== true) return;

        var index = this.state.liveEvents.findIndex(x => x.id == live.id);
        if (index < 0) {
            alert("Failed to delete live event! Does not exist locally.");
            return;
        }

        this.setState({ levelUpdating: true });
        var editedCount = await API.deleteLiveEvent(live.id);
        this.setState({ levelUpdating: false });

        if (editedCount === 0) {
            alert("Failed to delete live event!");
            return;
        }

        this.state.liveEvents.splice(index, 1);
        this.setState({
            liveEvents: this.state.liveEvents.slice(0)
        })
    }

    async createLive() {
        this.setState({ levelUpdating: true });
        const lv = new LiveEvent();
        var newLevel: any = await API.createOrUpdateLiveEvent(null, lv);
        this.setState({ levelUpdating: false });

        var nwl: LiveEvent = newLevel;
        if (!newLevel || nwl.id <= 0) {
            alert("Failed to create live event!");
            return;
        }

        this.setState({
            liveEvents: this.state.liveEvents.concat([ nwl ])
        })
    }

    liveEventCaptionChange(e: any, live: LiveEvent) {
        const newCaption: string = e.target.value;

        live.caption = newCaption;

        this.setState({
            liveEvents: this.state.liveEvents.slice(0)
        })
    }

    liveEventTimeChange(e: any, live: LiveEvent) {
        const newTime: string = e.target.value;

        live.startTime = newTime;

        this.setState({
            liveEvents: this.state.liveEvents.slice(0)
        })
    }

    liveEventLinkChange(e: any, live: LiveEvent) {
        const newLink: string = e.target.value;

        live.link = newLink;

        this.setState({
            liveEvents: this.state.liveEvents.slice(0)
        })
    }

    liveEventLiveChange(e: any, live: LiveEvent) {
        const state: boolean = e.target.checked;

        live.isLive = state;

        this.setState({
            liveEvents: this.state.liveEvents.slice(0)
        })
    }

    async masterPasswordChange(e: any) {
        const state: boolean = e.target.checked;
        const oldval = this.state.masterPasswordRevealed;

        if (this.state.levelUpdating) return;

        this.setState({
            levelUpdating: true,
            masterPasswordRevealed: state
        });

        var output = await API.setCountdown(state ? "" : "hidden");

        if (output == null) {
            // revert on failure
            this.setState({
                masterPasswordRevealed: oldval
            });
        }

        this.setState({
            levelUpdating: false
        });
    }

    public render() {
        return (
            <div className="page-content">
                <h1>Dashboard</h1>
                <div className="fancyBox dashboardBox">
                    <Tabs defaultActiveKey="status">
                        <Tab eventKey="status" title="Status">
                            Server status and logs, traffic data
                            <div className="sessionCountBox">
                                Sessions today: <div>{this.state.sessionCount}</div>
                            </div>
                            <div className="logsBox smaller">
                                {/*
                                 TYPES OF LOGS:
                                 AdministrativeEvent = 1,
                                 SecurityEvent = 0,
                                 UserEvents = -1
                                 */}
                                {this.state.logs.slice(0).reverse().map(log => {
                                    let extraClass = "";
                                    if (log.type === 1) extraClass = "adminlog";
                                    else if (log.type === 0) extraClass = "securitylog";
                                    else if (log.type === -1) return; // ignore user logs here, display them below in separate log list

                                    return (
                                        <div key={log.key} className={`log-item ${extraClass}`}>
                                            <div className="log-time">{log.time}</div><div className="log-msg">{log.message}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="logsBox">
                                {this.state.logs.slice(0).reverse().map(log => {
                                    let extraClass = "userlog";
                                    if (log.type !== -1) return;

                                    return (
                                        <div key={log.key} className={`log-item ${extraClass}`}>
                                            <div className="log-time">{log.time}</div><div className="log-msg">{log.message}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="trafficBox">
                                <this.getTraffic />
                                <this.getUniqueIPs />
                            </div>
                        </Tab>
                        <Tab eventKey="data" title="Data">
                            Website data, level passwords
                            <div className="levelsBox">
                                {this.state.levels.map(level => (
                                    <div key={level.id} className="level-item">
                                        <div className="level-index">{level.index}</div>
                                        <div className="level-caption">{level.caption}</div>
                                        <div className="level-password">
                                            <input className="fancy" type="text" value={level.password} onChange={(e) => this.levelPasswordChange(e, level)} />
                                            <button className={`button darker ${(this.state.levelUpdating ? "disabled" : "")}`} onClick={() => this.updatePassword(level)}>Save</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="revealBox">
                                <input type="checkbox" className={`${(this.state.levelUpdating ? "disabled" : "")}`} checked={this.state.masterPasswordRevealed} onChange={this.masterPasswordChange} />
                                <span>Reveal master password to everyone</span>
                            </div>
                            <div className="levelsBox">
                                <div>Live events / links:</div>
                                {this.state.liveEvents.map(live => (
                                    <div key={live.id} className="live-item">
                                        <div className="checkb">
                                            <input type="checkbox" className={`${(this.state.levelUpdating ? "disabled" : "")}`} checked={live.isLive} onChange={(e) => this.liveEventLiveChange(e, live)} />
                                            <span>Live</span>
                                        </div>
                                        <input className="fancy short" type="text" placeholder="Time" value={live.startTime} onChange={(e) => this.liveEventTimeChange(e, live)} />
                                        <input className="fancy" type="text" placeholder="Caption" value={live.caption} onChange={(e) => this.liveEventCaptionChange(e, live)} />
                                        <input className="fancy" type="text" placeholder="Link" value={live.link} onChange={(e) => this.liveEventLinkChange(e, live)} />
                                        <button className={`button margin short darker ${(this.state.levelUpdating ? "disabled" : "")}`} onClick={() => this.saveLive(live)}>Save</button>
                                        <button className={`button margin short darker ${(this.state.levelUpdating ? "disabled" : "")}`} onClick={() => this.deleteLive(live)}>Delete</button>
                                    </div>
                                ))}
                                <button className={`button margin darker ${(this.state.levelUpdating ? "disabled" : "")}`} onClick={() => this.createLive()}>Create new</button>
                            </div>
                        </Tab>
                        <Tab eventKey="forms" title="Forms">
                            Displaying up to {this.formLimit} most recent uploaded forms: (Currently: {this.state.forms.length})
                            <div className="formsBox">
                                {this.state.forms.map(form => (
                                    <div key={form.id} className="form-item">
                                        <div className="form-time">{Utility.getDateTime(form.created)}</div>
                                        <div className="form-ip">{form.ip}</div>
                                        <div className="form-esc">{form.elementarySchool}</div>
                                        <div className="form-class">{form.class}</div>
                                        <div className="form-program">{Utility.getProgram(form.program)}</div>
                                        <div className="form-certainty">{Utility.getCertainty(form.certainty)}</div>
                                        <div className="form-osc">{form.otherSchool}</div>
                                        <div className="form-rating">{Utility.getRating(form.rating)}</div>
                                    </div>
                                ))}
                            </div>
                            <button className={`topmargin button ${(this.state.exportingForms ? "disabled" : "")}`} onClick={async () => {

                                // send request
                                if (!this.state.exportingForms) {
                                    this.downloadCSV();
                                }

                            }}>Export to CSV</button>
                        </Tab>
                    </Tabs>
                </div>
            </div >
        );
    }
};