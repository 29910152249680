import { Certificate } from 'crypto';
import * as React from 'react';
import { API } from '../../helpers/apiHelper';
import { State } from '../../state';
import YouTubeEmbed from '../partial/YoutubeEmbed';
import { CgSmile, CgSmileMouthOpen, CgSmileNeutral, CgSmileSad } from 'react-icons/cg';
import { FaRegAngry } from 'react-icons/fa';
import { Table } from 'react-bootstrap';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';

export default class Level12 extends React.PureComponent<{}, {
    uploadingForm: boolean,
    formSaved: boolean,
    elementarySchool: string | null,
    otherSchool: string | null,
    certainty: number | null,
    program: number | null,
    rating: number | null,
    classNumber: number | null,
}> {
    colorClass: string;
    level: number = 12;

    items: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Športna dokumentacija",
            pointOfInterest: 80,
            image: "",
            backgroundColor: "#814A0E",
            content: (
                <div className="graytxt">
                    <p>Učenci, ki se bodo prijavili v športni oddelek, morajo biti registrirani športniki/športnice, kar je razvidno iz uradne <b>Evidence registriranih in kategoriziranih športnikov</b>.  Dokazila, ki ji morajo učenci predložiti do <b>2.3.2022</b>: </p>

                    <ul className="normal">
                        <li>zdravniško potrdilo osebnega zdravnika, ali zdravnika specialista medicine dela in športa, ali potrdilo o rednem preventivnem pregledu kandidata v zadnjem letu, iz katerega je razvidno splošno zdravstveno stanje kandidata;</li>
                        <li>potrdilo nacionalne panožne športne zveze za vpis v športni oddelek, ki vsebuje:
                            <ul className="normal">
                                <li>izjavo trenerja o sodelovanju s športnim koordinatorjem in</li>
                                <li>podatke o doseženih športnih rezultatih kandidata v zadnjih dveh letih.</li>
                            </ul>
                        </li>
                    </ul>

                    <br /><br />
                    <p>Na podlagi dokazil in opravljenega pogovora z učenci bo šola izdala potrdilo o izpolnjevanju posebnih pogojev za vpis v športni oddelek.</p>

                    <p>Dokumentacija je dostopna tudi na:</p>
                    <a href="https://www.gov.si/zbirke/storitve/prijava-za-opravljanje-preizkusov-nadarjenosti-znanja-in-spretnosti-in-izpolnjevanje-posebnih-pogojev-za-vpis/">
                        https://www.gov.si/zbirke/storitve/prijava-za-opravljanje-preizkusov-nadarjenosti-znanja-in-spretnosti-in-izpolnjevanje-posebnih-pogojev-za-vpis/
                    </a>

                    <br /><br />
                    <p>
                        Dokumentacijo lahko pošljete po pošti na naslov Gimnazija Ptuj, Volkmerjeva cesta 15, 2250 Ptuj (športna dokumentacija) ali oddate osebno v tajništvu šole.
                    </p>
                    <p>Dokumenti:</p>
                    <ul className="normal">
                        <li><a href="/doc/Izjava-trenerja-o-sodelovanju-s-sportnim-koordinatorjem.doc">Izjava trenerja o sodelovanju s športnim koordinatorjem</a></li>
                        <li><a href="/doc/Potrdilo-nacionalne-panozne-sportne-zveze-za-vpis-v-sportni-oddelek.doc">Potrdilo nacionalne panožne zveze</a></li>
                    </ul>

                </div>
            ),
            useBackground: true,
            bgClass: "color-orange"
        },
        {
            id: "f",
            category: "",
            title: "Preizkus likovne nadarjenosti",
            pointOfInterest: 120,
            image: "",
            backgroundColor: "#959684",
            content: (
                <div className="graytxt">
                    <p>
                        PREIZKUS LIKOVNE NADARJENOSTI za vpis v program umetniška gimnazija - likovna smer bo potekal v ponedeljek, <b>15. 3. 2022, ob 14. uri</b> na Gimnaziji Ptuj.
                    </p>
                    <p>
                        <b>Prijava:</b>  Za opravljanje preizkusa likovne nadarjenosti se je potrebno prijaviti do <b>2.3.2022</b>. (prijavnica: <a href="http://gimptuj.splet.arnes.si/files/2020/01/prijava_likovna_nadarjenost.pdf">Prijavnica</a>)
                    </p>
                    <p>
                        Prijavnico pošljete na: <b>Gimnazija Ptuj, Volkmerjeva cesta 15</b>, 2250 (s pripisom prijava na preizkus) ali na: <b>darja.rokavec@guest.arnes.si</b>
                    </p>
                    <br />
                    <p>
                        Preizkus obsega:
                    </p>

                    <ul className="normal">
                        <li>študijsko risanje konkretnih predmetov z upoštevanjem zakonitosti perspektive, 50 minut;</li>
                        <li>preoblikovanje izhodišča (teme, besedila) v likovno delo skozi lastno likovno ustvarjalnost, 60 minut;</li>
                        <li>oblika in barva - ustvarjanje abstraktne likovne kompozicije z uporabo trdih(oglatih) in mehkih (krožnih) oblik in barve, 80 minut;</li>
                        <li>izdelava določenega tridimenzionalnega objekta – kipa (z upoštevanjem konstrukcijskih in prostorskih zakonitosti) iz papirja (šeleshamerja) z rezanjem, prepogibanjem in lepljenjem, 100 minut.</li>
                    </ul>
                    <br /> <br />
                    <p>
                        S seboj prinesite pet risalnih listov A3, pet listov šeleshamerja A3, svinčnike različnih trdot (HB, B1, B3…), radirko, šilček, tempera ali akrilne barve, pribor za slikanje (različne velikosti čopičev, paleto, lonček za vodo, krpo), škarje, grafični nož (olfa), lepilo, lepilni trak ( pleskarski lepilni trak) trikotnik ali ravnilo.
                    </p>

                    <p>Veliko ustvarjalnega navdiha vam želimo! 😊</p>
                </div>
            ),
            useBackground: true,
            bgClass: "color-yellow"
        },
        {
            id: "dd",
            category: "",
            title: "Priprave na preizkus likovne nadarjenosti",
            pointOfInterest: 120,
            image: "",
            backgroundColor: "#959684",
            content: (
                <div className="graytxt">
                    <p>Priprave organiziramo zato, da je preizkus za vas poznana in manj stresna situacija. Vsa obvestila spremljajte na spletni strani  <a href="https://www.gimptuj.si">www.gimptuj.si</a></p>

                    <ul className="normal">
                        <li>Priprave bodo potekale <b>9. in 10.3.2022 med 15. in 18. uro</b>, v učilnici 5 na Gimnaziji Ptuj.</li>
                        <li>Udeležba na pripravah nikakor ni pogoj za pristop k preizkusu in uspešnost pri preizkusu likovne nadarjenosti.</li>
                        <li>Vabimo vas, da se na priprave prijavite. Prijave nam bodo pomagale pri boljši organizaciji delavnic. Prijavite se najkasneje do <b>25. 2. 2022</b>.</li>
                    </ul>
                    <br /> <br />
                    <p><a href="https://forms.office.com/Pages/ResponsePage.aspx?id=LG_Vinr-7Um74DUKB7WKfG25SCm8r7JEoEIlHGzeBGxUMkpCTzRSNExQVU9MSlhFWURVMVdVOFpEVS4u">Prijavnica</a> na priprave</p>
                    <p>S seboj prinesite različne trdote svinčnikov (HB, B1, B3…), radirko, šilček, tempera ali akrilne barve, različne debeline čopičev, škarje, lepilo, lepilni trak, trikotnik ali ravnilo. Za vse ostale pripomočke bomo poskrbeli na Gimnaziji Ptuj.</p>
                    <p>Vabljeni! 😊</p>
                </div>
            ),
            useBackground: true,
            bgClass: "color-yellow-strong"
        },
        {
            id: "ersds",
            category: "",
            title: "Prijavnica za vpis",
            pointOfInterest: 120,
            image: "",
            backgroundColor: "#959684",
            content: (
                <div className="graytxt">
                    <p><a href="/pdf/prijava_za_vpis_v_srednjo_solo.pdf">Prijavnica</a></p>
                </div>
            ),
            useBackground: true,
            bgClass: "color-orange-weak"
        }
    ];


    constructor(props: any) {
        super(props);
        this.state = {
            uploadingForm: false,
            formSaved: State.formSaved == 1,
            elementarySchool: null,
            otherSchool: null,
            certainty: null,
            program: null,
            rating: null,
            classNumber: null
        };

        this.colorClass = State.getClassForLevel(12);
        this.formShow = this.formShow.bind(this);
        this.formUpload = this.formUpload.bind(this);
        this.onElementarySchoolChange = this.onElementarySchoolChange.bind(this);
        this.onOtherSchoolChange = this.onOtherSchoolChange.bind(this);
        this.onCertaintyChange = this.onCertaintyChange.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
        this.onProgramChange = this.onProgramChange.bind(this);
        this.onClassChange = this.onClassChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    onElementarySchoolChange(e: any) {
        this.setState({
            elementarySchool: e.target.value
        })
    }

    onOtherSchoolChange(e: any) {
        this.setState({
            otherSchool: e.target.value
        })
    }

    onCertaintyChange(e: any) {
        this.setState({
            certainty: +e.target.value
        })
    }

    onRatingChange(e: any) {
        this.setState({
            rating: +e.target.value
        })
    }

    onProgramChange(e: any) {
        this.setState({
            program: +e.target.value
        })
    }

    onClassChange(e: any) {
        this.setState({
            classNumber: +e.target.value
        })
    }

    async formUpload() {

        this.setState({
            uploadingForm: true
        });

        const loginResult = await API.formSave(
            this.state.elementarySchool,
            this.state.otherSchool,
            this.state.program,
            this.state.certainty,
            this.state.rating,
            this.state.classNumber);

        this.setState({
            uploadingForm: false
        });

        if (typeof loginResult == "string") {
            alert(loginResult);
        }
        else {
            State.saveForm();
            this.setState({
                formSaved: true
            });
        }
    }

    formShow(props: any) {
        if (!this.state.formSaved) {
            return (
                <>
                    <p className="left">
                        Zelo bi nam pomagalo, če bi odgovoril/a na spodnji vprašalnik! Je popolnoma anonimen.
                    </p>
                    <form onSubmit={(e) => e.preventDefault()} className="vprasalnik">
                        <label>Osnovna šola, ki jo obiskuješ:</label>
                        <input className={`fancy ${(this.state.uploadingForm ? "disabled" : "")}`} type="text" value={this.state.elementarySchool || ""} onChange={this.onElementarySchoolChange} />

                        <label>Obiskujem:</label>
                        <select name="class" className={`fancy ${(this.state.uploadingForm ? "disabled" : "")}`}
                            value={this.state.classNumber || "0"} onChange={this.onClassChange}>
                            <option value="0">-</option>
                            <option value="9">9. razred</option>
                            <option value="8">8. razred</option>
                        </select>

                        <label>Označi program, ki te zanima:</label>
                        <select name="program" className={`fancy ${(this.state.uploadingForm ? "disabled" : "")}`}
                            value={this.state.program || "0"} onChange={this.onProgramChange}>
                            <option value="0">-</option>
                            <option value="1">Splošna gimnazija</option>
                            <option value="2">Umetniška gimnazija - likovna smer</option>
                            <option value="3">Umetniška gimnazija - smer gledališče in film</option>
                            <option value="4">Športna gimnazija</option>
                        </select>

                        <label>Kako trdna je tvoja odločitev?</label>
                        <select name="certainty" className={`fancy ${(this.state.uploadingForm ? "disabled" : "")}`}
                            value={this.state.certainty || "0"} onChange={this.onCertaintyChange}>
                            <option value="0">-</option>
                            <option value="1">Zagotovo se bom vpisal/a</option>
                            <option value="2">Nisem še odločen/a, razmišljam tudi o drugi šoli</option>
                        </select>

                        {this.state.certainty === 2 && <>
                            <label>Druga šola o kateri razmišljaš:</label>
                            <input className={`fancy ${(this.state.uploadingForm ? "disabled" : "")}`} type="text" value={this.state.otherSchool || ""} onChange={this.onOtherSchoolChange} />
                        </>
                        }

                        <label>Kako si bil/a zadovoljen/a z informacijami na informativnem dnevu?</label>
                        <div className={`ratingBox ${(this.state.uploadingForm ? "disabled" : "")}`}>
                            <div className={`rating ${(this.state.rating == 5 ? "selected" : "")}`}
                                onClick={() => this.setState({ rating: 5 })}>
                                <CgSmileMouthOpen />
                            </div>
                            <div className={`rating ${(this.state.rating == 4 ? "selected" : "")}`}
                                onClick={() => this.setState({ rating: 4 })}>
                                <CgSmile />
                            </div>
                            <div className={`rating ${(this.state.rating == 3 ? "selected" : "")}`}
                                onClick={() => this.setState({ rating: 3 })}>
                                <CgSmileNeutral />
                            </div>
                            <div className={`rating ${(this.state.rating == 2 ? "selected" : "")}`}
                                onClick={() => this.setState({ rating: 2 })}>
                                <CgSmileSad />
                            </div>
                            <div className={`rating smallrating ${(this.state.rating == 1 ? "selected" : "")}`}
                                onClick={() => this.setState({ rating: 1 })}>
                                <FaRegAngry />
                            </div>
                        </div>


                        <button className={`button ${(this.state.uploadingForm ? "disabled" : "")}`} onClick={() => {

                            // validate fields first
                            if (!this.state.elementarySchool ||
                                !this.state.certainty ||
                                !this.state.program ||
                                !this.state.rating) {

                                if (!window.confirm("Niste izpolnili celotnega vprašalnika, ali želite vseeno oddati?")) return;
                            }

                            // send request
                            if (!this.state.uploadingForm) this.formUpload();

                        }}>Oddaj vprašalnik</button>
                    </form>
                </>
            )
        }
        else {
            return (
                <>
                    <p>
                        Vprašalnik oddan.
                    </p>
                </>
            )
        }
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/u7NpHSFleT8?rel=0" />

                    <h1>Vprašalnik</h1>
                    <div className="fancyBox">
                        <this.formShow />
                    </div>

                    <h1>Pomembni datumi</h1>
                    <div className="fancyBox">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Opis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>25.2.2022</td>
                                    <td>Prijava na brezplačne priprave za preizkus likovne nadarjenosti</td>
                                </tr>
                                <tr>
                                    <td>9. in 10.3.2022</td>
                                    <td>Brezplačna priprava na preizkus likovne nadarjenosti</td>
                                </tr>
                                <tr>
                                    <td>2.3.2022</td>
                                    <td>Prijave za opravljanje preizkusa likovne nadarjenosti</td>
                                </tr>
                                <tr>
                                    <td>17.3.2022 ob 14.00</td>
                                    <td>Preizkus likovne nadarjenosti za gimnazijo likovne smeri</td>
                                </tr>
                                <tr>
                                    <td>2.3.2022</td>
                                    <td>Posredovanje dokazil o izpolnjevanju športnih pogojev za vpis v športni oddelek</td>
                                </tr>
                                <tr>
                                    <td>od 11.3. do 21.3.2022</td>
                                    <td>Ugotavljanje vpisnih pogojev za vpis v športni oddelek z razgovori</td>
                                </tr>
                                <tr>
                                    <td>28.3.2022</td>
                                    <td>Izdaja potrdil o izpolnjevanju vpisnih pogojev za športni oddelek</td>
                                </tr>
                                <tr>
                                    <td>28.3.2022</td>
                                    <td>Izdaja potrdil o opravljenem preizkusu likovne nadarjenosti</td>
                                </tr>
                                <tr>
                                    <td>2.4.2022</td>
                                    <td>Oddaja prijave za vpis v 1. letnik</td>
                                </tr>
                                <tr>
                                    <td>8.4.2022</td>
                                    <td>Javna objava številčnega stanja prijav</td>
                                </tr>
                                <tr>
                                    <td>do 25.4.2022</td>
                                    <td>Morebitni prenosi prijav</td>
                                </tr>
                                <tr>
                                    <td>do 24.5.2022</td>
                                    <td>Obveščanje o omejitvi vpisa</td>
                                </tr>
                                <tr>
                                    <td>začetek junija</td>
                                    <td>Vabila k vpisu </td>
                                </tr>
                                <tr>
                                    <td>med 16.6 in 21.6.2022</td>
                                    <td>Vpis (brez omejitve vpisa)</td>
                                </tr>
                                <tr>
                                    <td>med 16.6 in 21.6.2022</td>
                                    <td>Izvedba 1. kroga (v primeru omejitve vpisa)</td>
                                </tr>
                                <tr>
                                    <td>do 30.6.2022</td>
                                    <td>Izvedba 2. kroga (v primeru omejitve vpisa)</td>
                                </tr>
                                <tr>
                                    <td>do 1.7.2022</td>
                                    <td>Vpis sprejetih po 2. krogu</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <br />
                    <ItemGrid items={this.items} />

                    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"><img src="/img/engineer.gif" alt="engineer" /></a>
                </div>
            </>
        );
    }
}