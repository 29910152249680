import * as React from 'react';
import Table from 'reactstrap/lib/Table';
import { State } from '../../state';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level1 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 1;

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(1);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan medium"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/uRV4g6hK6F4?rel=0" />
                    
                    <div className="embedCenterParent">
                        <iframe className="embedKartice" src="https://indd.adobe.com/embed/7a65c345-92cb-4f92-8a61-ae97b774222a?startpage=1&allowFullscreen=true"
                            width="525px" height="450px" frameBorder="0" allowFullScreen={true}></iframe>
                    </div>

                    <a className="demoman" href="https://cryshana.me/f/MHbkT9vLOs4s.mp4"><img src="/img/demoman.gif" alt="demoman"/></a>
                </div>
            </>
        );
    }
}