import * as React from 'react';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import ItemList, { ItemListItem } from '../animated/itemlist/itemlist';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level2 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 2;

    items: ItemListItem[] = [
        { key: 1, colorClass: "yellow", title: "Splošna gimnazija", content: this.GetSplosnaGimnazijaContent() },
        { key: 2, colorClass: "orange", title: "Umetniška gimnazija • Likovna smer", content: this.GetUmetniskaGimnazijaLikovnaContent() },
        { key: 3, colorClass: "green", title: "Umetniška gimnazija • Gledališče in film", content: this.GetUmetniskaGimnazijaFilmContent() },
        { key: 4, colorClass: "lightblue", title: "Športni oddelek", content: this.GetSportniOddelekContent() },
        { key: 5, colorClass: "gray", title: "Maturitetni tečaj", content: this.GetMaturitetniTecajContent() }
    ];

    constructor(props: any) {
        super(props);
        this.colorClass = State.getClassForLevel(2);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    readMore() {
        const [readMore, setReadMore] = React.useState(false);

        return (
            <>
                <a className="readMoreLink" onClick={() => setReadMore(!readMore)}>{readMore ? "Manj..." : "Več..."}</a>
                {readMore && (
                    <ol>
                        <li>Dodatna ura matematike in slovenščine  v 2. in 3. letniku. V 4. letniku pa pouk matematike na osnovni ali višji ravni za pripravo na maturo.</li>
                        <li>Pouk tujih jezikov na dveh ravneh pri drugem tujem je­ziku (angleščina, nemščina), in sicer v 1., 2. 3. in 4. letniku, v 4. letniku pa pri izbranem tujem jeziku dodatne ure za pripravo na osnovni in višji ravni na maturi.</li>
                        <li>Priprava dijakov za pridobitev jezikovnih diplom oziroma certifikatov iz tujih jezikov (nemščina, francoščina).</li>
                        <li>Medpredmetno povezovanje v projektnih dneh in tednih. </li>
                        <li>Spodbujanje radovednosti in raziskovalnega dela.</li>
                        <li>Priložnosti za družbeno odgovorne aktivnosti.</li>
                        <li>Kreativnost in uresničevanje idej. </li>
                        <li>Vrednote sodelovanja in solidarnosti, sprejemanja različnosti, strpnosti in prijateljski odnosi.</li>
                        <li>Obvezne in izbirne strokovne ekskurzije.</li>
                        <li>Narodne in mednarodne izmenjave in projekti.</li>
                    </ol>
                )}
            </>
        );
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan small"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/91e0FWD8Z8Q?rel=0" />

                    <h1 className="center">Gimnazija? Gimnazija Ptuj? Tu si na pravi poti.</h1>
                    <div className="fancyBox">
                        <ul>
                            <li>Noben drugi srednješolski izobraževalni program ne ponuja tako široke palete učenja različnih jezikov, družboslovnih in naravoslovnih vsebin, slovenščine, matematike, umetnosti in izbirnih vsebin.</li>
                            <li>Noben drug program ne pripravlja na splošno maturo. Splošna matura je vstopnica za uspešni študij … česarkoli in kjerkoli. </li>
                            <li>Noben drugi program ne odpre toliko možnosti in priložnosti.</li>
                        </ul>

                        <this.readMore />
                    </div>

                    <ItemList items={this.items} />

                    <h1>Predmetniki</h1>
                    <div className="fancyBox">
                        <ul>
                            <li><a href="/pdf/predmetniki-gimPtuj-splošna-gimnazija.pdf">Splošna gimnazija</a></li>
                            <li><a href="/pdf/predmetniki-gimPtuj-likovna-smer.pdf">Umetnostna gimnazija • Likovna smer</a></li>
                            <li><a href="/pdf/predmetniki-gimPtuj-GF1.pdf">Umetnostna gimnazija • Gledališče in film</a></li>
                            <li><a href="/pdf/predmetniki-gimPtuj-športni-oddelek.pdf">Športni oddelek</a></li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    GetSplosnaGimnazijaContent(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Zakaj?</p>
                    <ul>
                        <li>Dijaki pridobijo široko splošno izobrazbo in razgledanost.</li>
                        <li>Pri naravoslovnih predmetih se dijaki delijo na manjše skupine za delo v  sodobno opremljenih laboratorijih za biološke, kemijske ali fizikalne poskuse.</li>
                        <li>Pouk je obogaten  z izbirnima predmetoma umetnostna zgodovina in vzgoja za solidarnost.</li>
                        <li>Medpredmetno povezovanje splošnih predmetov omogoča dijakom  celostno dojemanje znanja. </li>
                    </ul>
                    <br />
                    <p>Za splošno maturo dijaki izbirajo med splošnimi predmeti. Dijaki se odločijo za 2 izbirna predmeta glede na svoje študijske namere. Za študij  na področju naravoslovja, tehnike, medicinskih ved ali informatike izbirajo med  kemijo, fiziko, biologijo ali informatiko. Za družboslovne, podjetniške ali humanistične študije izbirajo med sociologijo, psihologijo, zgodovino, umetnostno zgodovino, geografijo ali filozofijo. Za študij na jezikovnem  področju  pa nemški, angleški ali francoski jezik.</p>
                    <p>Število vpisnih mest: 112</p>
                </div>
            </>
        );
    }

    GetUmetniskaGimnazijaLikovnaContent(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Zakaj?</p>
                    <p>Dijaki</p>
                    <ul>
                        <li>izostrijo  vedenje o estetiki – lepem v vsakdanjem življenju,</li>
                        <li>imajo možnost ustvarjati in izražati sebe,</li>
                        <li>pridobivajo teoretično znanje s področja likovne umetnosti, arhitekture in bivalne kulture.</li>
                    </ul>
                    <br />
                    <p>Pri strokovnih predmetih dobijo dijaki  kvalitetne osnove za študij na programih, ki jih izobrazijo za likovne pedagoge, arhitekte, modne, grafične, notranje, tekstilne, vizualne, industrijske in spletne oblikovalce ali oblikovalce gledališko filmske scene in opreme, dekoraterje, restavratorje, kustose, tudi slikarje, kiparje, grafike, likovne kritike …</p>
                    <p>Ob bogatih umetniških vsebinah pa dobijo dijaki tudi dovolj splošnega znanja za uspešno opravljanje splošne mature in lahko svojo izobraževalno pot nadaljujejo na različnih fakultetah, enako kot dijaki splošne gimnazije in športnega oddelka.</p>
                    <p>Z znanjem iz strokovnih predmetov (risanje in slikanje, likovna teorija, umetnostna zgodovina, predstavitvene tehnike, bivalna kultura, plastično oblikovanje …) pa bodo na podlagi razvite osebne estetike vedeli več in znali ceniti lepo na vseh poklicnih področjih.</p>
                    <p>Za splošno maturo dijaki izbirajo med splošnimi predmeti, lahko pa izberejo strokovni predmet likovna teorija.</p>
                    <p>Število vpisnih mest: 14</p>
                </div>
            </>
        );
    }

    GetUmetniskaGimnazijaFilmContent(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Zakaj?</p>
                    <p>Dijaki</p>
                    <ul>
                        <li>se naučijo dobrega, učinkovitega in prepričljivega nastopa ter filmskega in fotografskega jezika,</li>
                        <li>imajo priložnost  doživeti, ustvarjati in poustvarjati vsebine gledališke in filmske umetnosti,</li>
                        <li>pridobivajo teoretična znanja s področij gledališča, sodobnega plesa, videa in filma.</li>
                    </ul>
                    <br />
                    <p>V 1. in 2. letniku pridobivajo dijaki temeljna znanja in veščine s področja gledališča in filma, v 3. in 4. letniku pa si dijaki izberejo poglobljeno spoznavanje enega ali drugega področja.</p>
                    <p>Kljub poudarjenim umetniškim vsebinam pa program ni namenjen izključno dijakom, ki jih zanima študij  igralstva ali gledališke in filmske režije. V programu je dovolj znanja s področij jezikoslovja in humanistike, da si dijaki lahko izberejo nadaljevanje izobraževalne poti na različnih fakultetah, enako kot dijaki splošne gimnazije in športnega oddelka.</p>
                    <p>Znanje iz strokovnih predmetov (umetnost govora, gib, video in film, zgodovina in teorija gledališča in filma) pa bodo koristno uporabili  na vseh poklicnih področjih.</p>
                    <p>Za splošno maturo dijaki izbirajo med splošnimi predmeti, lahko pa izberejo strokovni predmet zgodovina in teorija gledališča in filma.</p>
                    <p>Število vpisnih mest: 14</p>
                </div>
            </>
        );
    }

    GetSportniOddelekContent(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Zakaj?</p>
                    <ul>
                        <li>Več ur športne vzgoje;</li>
                        <li>opravljeni treningi štejejo k obveznim izbirnim vsebinam in je zato manj obveznosti ob pouku;</li>
                        <li>pouk se lahko individualno prilagaja vsakemu dijaku, glede na intenzivnost športnih obremenitev (treningi, priprave, tekmovanja);</li>
                        <li>način dela zagotavlja uspešno športno kariero, učni uspeh in opravljeno splošno maturo;</li>
                        <li>število dijakov v oddelku je od 18 do 22;</li>
                        <li>poučujejo profesorji, ki imajo do športa pozitiven odnos in jih šport zanima;</li>
                        <li>poleg profesorjev in razrednika za usklajevanje obveznosti skrbita pedagoški in športni koordinator;</li>
                        <li>omogočeno je prehajanje med splošnim in športnim oddelkom;</li>
                        <li>za dijake športnih oddelkov so organizirani športni tabori, vsako leto iz različnih športnih področij.</li>
                    </ul>
                    <br />
                    <p>Dijaki v športnem oddelku  izbirajo za maturo med vsemi splošno izobraževalnimi predmeti, enako kot dijaki v  splošni gimnaziji.</p>
                    <p>Število vpisnih mest: 18</p>
                </div>
            </>
        );
    }

    GetMaturitetniTecajContent(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Maturitetni tečaj je namenjen dijakom, ki izpolnjujejo pogoje za vključitev v program, da se z organiziranim in rednim poukom pripravijo na splošno maturo in jo opravijo. Traja eno šolsko leto (29 tednov).</p>
                    <p><b>VPIS</b> na maturitetni tečaj je možen z uspešno končanim:</p>
                    <ul>
                        <li>srednjim poklicnim izobraževanjem,</li>
                        <li>srednjim tehniškim oz. strokovnim izobraževanjem,</li>
                        <li>s tretjim letnikom gimnazije, če je prekinitev izobraževanja trajala najmanj eno leto,</li>
                        <li>z osnovnošolskim izobraževanjem in hkrati opravljenim preizkusom znanja na ravni tretjega letnika gimnazije.</li>
                    </ul> <br />
                    <p>Dijak opravi maturitetni tečaj, ko je pozitivno ocenjen pri vseh predmetih in opravi obveznosti (pouk, vaje, seminarske naloge), določene s programom in s predmetnimi izpitnimi katalogi. Z uspešno opravljenim maturitetnim tečajem dijak pridobi pravico do opravljanja mature.</p>
                    <p>Število vpisnih mest je 30.</p>
                </div>
            </>
        );
    }
}