import * as React from 'react';
import { Route } from 'react-router';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { State } from '../state';

export default class LogClient {
    connection: HubConnection | null = null;
    logCallback: (log: string, type: number) => void = (l, s) => console.log("LOG RECEIVED: ", l, s);
    formCallback: (id: number, ip: string, eSchool: string, oSchool: string, rating: number, certainty: number, program: number, classNumber: number) => void = (i, e, o, r, c, p) => console.log("FORM RECEIVED: ", i, e, o, r, c, p);
    sessionCountCallback: (count: number) => void = (c) => { };

    constructor(
        logCallback: (log: string, type: number) => void,
        formCallback: (id: number, ip: string, eSchool: string, oSchool: string, rating: number, certainty: number, program: number, classNumber: number) => void,
        sessionCountCallback: (count: number) => void) {

        this.logCallback = logCallback;
        this.formCallback = formCallback;
        this.sessionCountCallback = sessionCountCallback;

        this.initialize();
    }

    public initialize() {
        this.connection = new HubConnectionBuilder()
            .withUrl("/hubs/log", { accessTokenFactory: () => State.login?.token ?? "" })
            .withAutomaticReconnect()
            .build();

        this.connection.on("ReceiveLog", (message: string, type: number) => {
            this.logCallback(message, type);
        });

        this.connection.on("ReceiveForm", (id: number, ip: string, eSchool: string, oSchool: string, rating: number, certainty: number, program: number, classNumber: number) => {
            this.formCallback(id, ip, eSchool, oSchool, rating, certainty, program, classNumber);
        });

        this.connection.on("SessionCounterUpdate", (count: number) => {
            this.sessionCountCallback(count);
        });

        this.connection.start()
            .then(result => {
                console.log("Connected");
            })
            .catch(e => {
                console.error("Connection failed!", e);
            });
    }
}