import * as React from 'react';
import { History } from 'history';
import { API } from '../../helpers/apiHelper';
import { State } from '../../state';
import './admin.css';

export default class Login extends React.PureComponent<{ onLoaded: () => void, history: History }, {
    id: string,
    password: string,
    error: string,
    loggingIn: boolean
}> {
    constructor(props: any) {
        super(props);

        this.state = {
            id: "",
            password: "",
            error: "",
            loggingIn: false
        };

        this.onIdChange = this.onIdChange.bind(this);
        this.onPassChange = this.onPassChange.bind(this);
    }

    componentDidMount() {
        this.props.onLoaded();

        // if already logged in, redirect to dashboard
        if (State.isLoggedIn()) {
            this.props.history.push("/dashboard");
        }
    }

    onIdChange(e: any) {
        this.setState({
            id: e.target.value
        })
    }

    onPassChange(e: any) {
        this.setState({
            password: e.target.value
        })
    }

    async loginRequest(id: string, pass: string) {

        this.setState({
            loggingIn: true
        });
        const loginResult = await API.login(id, pass);
        this.setState({
            loggingIn: false
        });

        if (typeof loginResult == "string") {
            this.setState({
                error: loginResult
            });

            alert(loginResult);
        }
        else {
            State.setLogin(loginResult);

            // redirect to dashboard
            this.props.history.push("/dashboard");
        }
    }

    public render() {
        return (
            <div className="page-content">
                <h1>Login</h1>
                <div className="fancyBox">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <input className={`fancy ${(this.state.loggingIn ? "disabled" : "")}`} type="text" value={this.state.id} onChange={this.onIdChange} placeholder="ID" />
                        <input className={`fancy ${(this.state.loggingIn ? "disabled" : "")}`} type="password" value={this.state.password} onChange={this.onPassChange} placeholder="Password" />
                        <button className={`button ${(this.state.loggingIn ? "disabled" : "")}`} onClick={() => {

                            // send request
                            if (!this.state.loggingIn) {
                                this.loginRequest(this.state.id, this.state.password);
                            }
                            
                        }}>Login</button>
                    </form>
                </div>
            </div>
        );
    }
};