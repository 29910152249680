class UtilityClass {
    // returns time in format "HH:mm" or "HH:mm:ss" from given date
    public getTime(time: Date, showSeconds: boolean = false): string {
        var hours = "" + time.getHours();
        var minutes = "" + time.getMinutes();
        var seconds = "" + time.getSeconds();
        if (time.getHours() <= 9) hours = "0" + hours;
        if (time.getMinutes() <= 9) minutes = "0" + minutes;
        if (time.getSeconds() <= 9) seconds = "0" + seconds;

        if (showSeconds) return `${hours}:${minutes}:${seconds}`;
        else return `${hours}:${minutes}`;
    }

    // returns current time in format "HH:mm" and optional seconds "HH:mm:ss"
    public getCurrentTime(showSeconds: boolean = false): string {
        const now = new Date();
        return this.getTime(now, showSeconds);
    }

    // returns localized date from UTC date
    public getLocalizedDate(timeUTC: Date, showSeconds: boolean = false): Date {
        let datestring = timeUTC.toString();
        if (datestring.endsWith("Z") === false) datestring += "Z";
        const localDate = new Date(datestring);
        return localDate;
    }

    // returns current datetime in format "d.M HH:mm"
    public getCurrentTimeDateForm(): string {
        return this.getShortDateTime(new Date());
    }

    // returns localized datetime in format "d.M HH:mm"
    public getLocalizedTimeDateForm(timeUTC: Date): string {
        const date = this.getLocalizedDate(timeUTC);
        return this.getShortDateTime(date);
    }

    // returns localized time in format "HH:mm" with optional seconds "HH:mm:ss"
    public getLocalizedTime(timeUTC: Date, showSeconds: boolean = false): string {
        let datestring = timeUTC.toString();
        if (datestring.endsWith("Z") === false) datestring += "Z";
        const localDate = new Date(datestring);
        return this.getTime(localDate, showSeconds);
    }

    // returns date in format "d. MMMM"
    public getPrettyDate(date: Date): string {
        const d = new Date(date).toLocaleDateString("en-US", { day: 'numeric', month: 'long' })

        return d;
    }

    // returns date in format "d. M."
    public getShortDate(date: Date): string {
        const d = new Date(date).toLocaleDateString("sl-SI", { day: 'numeric', month: 'numeric' })
        return d;
    }

    // returns datetime in format "d.M | HH:mm"
    public getShortDateTime(date: Date): string {
        const d = new Date(date);

        const dt = `${d.getDate()}.${(d.getMonth() + 1)}`;
        const time = this.getTime(d);

        return `${dt} | ${time}`;
    }

    // returns full datetime in full format "d.M.yyyy HH:mm"
    public getDateTime(d: Date): string {
        var date = new Date(d);
        var hours = "" + date.getHours();
        var minutes = "" + date.getMinutes();
        var seconds = "" + date.getSeconds();
        if (date.getHours() <= 9) hours = "0" + hours;
        if (date.getMinutes() <= 9) minutes = "0" + minutes;
        if (date.getSeconds() <= 9) seconds = "0" + seconds;

        var day = "" + date.getDate();
        var month = "" + (date.getMonth() + 1);
        var year = "" + date.getFullYear();

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    public getProgram(program: number): string {
        switch (program) {
            case 1: return "Splošna";
            case 2: return "Likovna";
            case 3: return "Gledališče/Film";
            case 4: return "Športna";
            default: return "-";
        }
    }

    public getCertainty(certainty: number): string {
        switch (certainty) {
            case 1: return "Zagotovo";
            case 2: return "Neodločen/a";
            default: return "-";
        }
    }

    public getRating(rating: number): string {
        switch (rating) {
            case 5: return "Zelo zadovoljen/a";
            case 4: return "Zadovoljen/a";
            case 3: return "Nevtralno";
            case 2: return "Nezadovoljen/a";
            case 1: return "Zelo nezadovoljen/a";
            default: return "-";
        }
    }
}

export const Utility = new UtilityClass();