import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import { State } from '../../state';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level9 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 9;

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(9);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan larger"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/2ClEpgqBQtw?rel=0" />

                    <br />
                    <h1 className="center">Pouk pri umetniških predmetih</h1>
                    <br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/4G0jP5Mgb4E?rel=0" />

                    <br /><br />
                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/stoli/0.jpg",
                                    "/img/sliders/stoli/1.jpg",
                                    "/img/sliders/stoli/2.jpg",
                                    "/img/sliders/stoli/3.jpg",
                                    "/img/sliders/stoli/4.jpg",
                                    "/img/sliders/stoli/5.jpg",
                                    "/img/sliders/stoli/6.jpg",
                                    "/img/sliders/stoli/7.jpg",
                                    "/img/sliders/stoli/8.jpg",
                                    "/img/sliders/stoli/9.jpg",
                                    "/img/sliders/stoli/10.jpg",
                                    "/img/sliders/stoli/11.jpg",
                                    "/img/sliders/stoli/12.jpg",
                                    "/img/sliders/stoli/13.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <br />
                    <YouTubeEmbed src="https://www.youtube.com/embed/bzDyhbbUsc8?rel=0" />

                    <br /><br />
                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/dramoris/0.jpg",
                                    "/img/sliders/dramoris/1.jpg",
                                    "/img/sliders/dramoris/2.jpg",
                                    "/img/sliders/dramoris/3.jpg",
                                    "/img/sliders/dramoris/4.jpg",
                                    "/img/sliders/dramoris/5.jpg",
                                    "/img/sliders/dramoris/6.jpg",
                                    "/img/sliders/dramoris/7.jpg",
                                    "/img/sliders/dramoris/8.jpg",
                                    "/img/sliders/dramoris/9.jpg",
                                    "/img/sliders/dramoris/10.jpg",
                                    "/img/sliders/dramoris/11.jpg",
                                    "/img/sliders/dramoris/12.jpg",
                                    "/img/sliders/dramoris/13.jpg",
                                    "/img/sliders/dramoris/14.jpg",
                                    "/img/sliders/dramoris/15.jpg",
                                    "/img/sliders/dramoris/16.jpg",
                                    "/img/sliders/dramoris/17.jpg",
                                    "/img/sliders/dramoris/18.jpg",
                                    "/img/sliders/dramoris/19.jpg",
                                    "/img/sliders/dramoris/20.jpg",
                                    "/img/sliders/dramoris/21.jpg",
                                    "/img/sliders/dramoris/22.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/foto/0.jpg",
                                    "/img/sliders/foto/1.jpg",
                                    "/img/sliders/foto/2.jpg",
                                    "/img/sliders/foto/3.jpg",
                                    "/img/sliders/foto/4.jpg",
                                    "/img/sliders/foto/5.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/risanje/0.jpg",
                                    "/img/sliders/risanje/1.jpg",
                                    "/img/sliders/risanje/2.jpg",
                                    "/img/sliders/risanje/3.jpg",
                                    "/img/sliders/risanje/4.jpg",
                                    "/img/sliders/risanje/5.jpg",
                                    "/img/sliders/risanje/6.jpg",
                                    "/img/sliders/risanje/7.jpg",
                                    "/img/sliders/risanje/8.jpg",
                                    "/img/sliders/risanje/9.jpg",
                                    "/img/sliders/risanje/10.jpg",
                                    "/img/sliders/risanje/11.jpg",
                                    "/img/sliders/risanje/12.jpg",
                                    "/img/sliders/risanje/13.jpg",
                                    "/img/sliders/risanje/14.jpg",
                                    "/img/sliders/risanje/15.jpg",
                                    "/img/sliders/risanje/16.jpg",
                                    "/img/sliders/risanje/17.jpg",
                                    "/img/sliders/risanje/18.jpg",
                                    "/img/sliders/risanje/19.jpg",
                                    "/img/sliders/risanje/20.jpg",
                                    "/img/sliders/risanje/21.jpg",
                                    "/img/sliders/risanje/22.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/tehnike/0.jpg",
                                    "/img/sliders/tehnike/1.jpg",
                                    "/img/sliders/tehnike/2.jpg",
                                    "/img/sliders/tehnike/3.jpg",
                                    "/img/sliders/tehnike/4.jpg",
                                    "/img/sliders/tehnike/5.jpg",
                                    "/img/sliders/tehnike/6.jpg",
                                    "/img/sliders/tehnike/7.jpg",
                                    "/img/sliders/tehnike/8.jpg",
                                    "/img/sliders/tehnike/9.jpg",
                                    "/img/sliders/tehnike/10.jpg",
                                    "/img/sliders/tehnike/11.jpg",
                                    "/img/sliders/tehnike/12.jpg",
                                    "/img/sliders/tehnike/13.jpg",
                                    "/img/sliders/tehnike/14.jpg",
                                    "/img/sliders/tehnike/15.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}