import * as React from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./itemlist.css";

export class ItemListItem {
    key: number;
    title: string;
    colorClass: string;
    content: JSX.Element | null;

    constructor(key: number, title: string, content: JSX.Element | null) {
        this.key = key;
        this.title = "";
        this.colorClass = "gray";
        this.content = content;
    }
}

export default class ItemList extends React.PureComponent<{ items: ItemListItem[] }, {}> {
    constructor(props: any) {
        super(props);

        this.Item = this.Item.bind(this);
        this.Content = this.Content.bind(this);
        this.ContentRows = this.ContentRows.bind(this);
    }

    Item(props: any) {
        const content: JSX.Element | null = props.content;

        const [isOpen, setIsOpen] = React.useState(false);

        const toggleOpen = () => setIsOpen(!isOpen);

        return (
            <motion.li className="fr-li" layout onClick={toggleOpen} initial={{ borderRadius: 10 }}>
                <motion.div layout className="itemListAvatar">
                    <div className={"fr-avatar " + props.colorClass} />
                    <div className="fr-title">{props.title}</div>
                </motion.div>

                <AnimatePresence>{isOpen && <this.Content content={content} />}</AnimatePresence>
            </motion.li>
        );
    }

    Content(props: any) {
        const content: JSX.Element | null = props.content;

        return (
            <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <this.ContentRows content={content} />
            </motion.div>
        );
    }

    ContentRows(props: any) {
        const content: JSX.Element | null = props.content;

        if (!content) {
            return (
                <>
                    <div className="fr-row" />
                    <div className="fr-row" />
                    <div className="fr-row" />
                </>
            );
        }
        else return content;
    }

    public render() {
        return (
            <AnimateSharedLayout>
                <motion.ul className="fr-ul" layout initial={{ borderRadius: 15 }}>
                    {this.props.items.map(item => (
                        <this.Item key={item.key} title={item.title} content={item.content} colorClass={item.colorClass} />
                    ))}
                </motion.ul>
            </AnimateSharedLayout>
        );
    }
}