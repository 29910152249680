import * as React from 'react';

export default class Locked extends React.PureComponent<{}> {

    public render() {
        return (
            <div>
                <h1>Zaklenjeno</h1>
                <p>Ta korak je zaklenjen</p>
            </div>
        );
    }
}