import * as React from 'react';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level11 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 11;
    
    items: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Učbeniški sklad",
            pointOfInterest: 80,
            image: "/img/covers/book-cover.jpg",
            backgroundColor: "#814A0E",
            content: (
                <div>
                    <p>
                    V sklopu šolske knjižnice Gimnazije Ptuj je tudi učbeniški sklad, v katerem si naši dijaki lahko izposodijo učbenike predpisane za posamezni letnik. Dijaki 1., 2. in 3. letnikov si  lahko izposodijo učbeniške komplete, dijaki 4. letnikov pa si ob učbenikih za slovenščino, matematiko in zgodovino lahko izposodijo še predpisane učbenike izbranih izbirnih predmetov zaključnega letnika.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "f",
            category: "",
            title: "Malica",
            pointOfInterest: 120,
            image: "/img/covers/food-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                    V glavnem odmoru od 11.15 do 11.45 imajo dijaki Gimnazije Ptuj čas za toplo malico. Vsak dan so dijakom na voljo 3 topli meniji. Poskrbljeno pa je tudi za dijake s predpisanimi dietami. Na toplo malico odhajajo dijaki v Šolski center Ptuj, prigrizke in posladke pa si lahko kupijo tudi v šolski Petici, ki je odprta v času pouka.  Za naročanje na malico dijaki uporabljajo sodobni računalniški sistem naročanja hrane, ki omogoča dnevne spremembe in odpovedi naročila.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(11);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan medium vsakdanSlogan"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/o1oHDXXixm8?rel=0" />

                    <br />
                    <h1 className="center">Od osmih do dveh</h1>
                    <br/>
                    <YouTubeEmbed src="https://www.youtube.com/embed/dM1UH0T7Qos?rel=0" />

                    <br />
                    <ItemGrid items={this.items} />
                </div>
            </>
        );
    }
}