import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import { State } from '../../state';
import ItemGrid, { ItemGridItem } from '../animated/itemgrid/itemgrid';
import ItemList, { ItemListItem } from '../animated/itemlist/itemlist';
import YouTubeEmbed from '../partial/YoutubeEmbed';

export default class Level6 extends React.PureComponent<{}, {}> {
    colorClass: string;
    level: number = 6;

    items: ItemListItem[] = [
        { key: 1, colorClass: "darkblue", title: "DSD", content: this.GetDSD() },
        { key: 2, colorClass: "lightblue", title: "DELF", content: this.GetDELF() }
    ];

    gridItems: ItemGridItem[] = [
        {
            id: "c",
            category: "",
            title: "Evropski dan jezikov",
            pointOfInterest: 80,
            image: "/img/covers/evj-cover.jpg",
            backgroundColor: "#814A0E",
            content: (
                <div>
                    <p>
                        Vsako leto 26. septembra po vsej Evropi praznujemo evropski dan jezikov, ki ga je svet Evrope prvič razglasil v evropskem letu jezikov 2001. Ta dan je namenjen osveščanju javnosti o bogati kulturni in jezikovni različnosti v Evropi ter o pomenu učenja jezikov za utrjevanje evropskih načel strpnosti in medsebojnega razumevanja. Cilj pobude je tudi spodbujanje učenja jezikov in vseživljenjskega učenja. Evropska unija ima 24 jezikov, med katerimi je od leta 2004 tudi slovenščina. Evropski dan jezikov želi posebej opozoriti na manj razširjene jezike, ki se jih ljudje redkeje učijo, in na pomen varovanja ogroženih jezikov.
                    </p>
                    <p>
                        Tudi dijaki in dijakinje Gimnazije Ptuj že več let obeležujemo evropski dan jezikov. Odpravimo se na ulice Ptuja, kjer nagovarjamo mimoidoče v slovenščini, angleščini, nemščini in francoščini. Opravimo kratke ankete o znanju tujih jezikov, skupaj se naučimo nekaj uporabnih fraz v želenem tujem jeziku, tujim turistom pa pomagamo, da usvojijo nekaj osnovnih izrazov v slovenščini.
                    </p>
                    <p>
                        Večjezičnost je eno od temeljnih načel EU, zato si želimo, da bi vsak državljan EU poleg maternega jezika znal vsaj dva tuja jezika.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        },
        {
            id: "f",
            category: "",
            title: "Sodelovanje z nemškim časopisom FAZ",
            pointOfInterest: 120,
            image: "/img/covers/faz-cover.jpg",
            backgroundColor: "#959684",
            content: (
                <div>
                    <p>
                        Gimnazija Ptuj tradicionalno sodeluje v mednarodnih izmenjavah in projektih, še posebej pri pouku nemškega jezika.
                    </p>
                    <p>
                        Programski učitelj iz Nemčije dr. Gerald Hühner,  ki je vrsto let pripravljal dijake Gimnazije Ptuj na izpit iz nemške jezikovne diplome (DSD) in poučeval pri medpredmetnem pouku v evropskih oddelkih, nas je vpeljal v mnoge uspešne projekte. V  šolskem letu 2018/2019  nas je ponovno povabil k sodelovanju, tokrat v mednarodni projekt Jugend schreibt – Mladina piše, ki ga razpisuje velika nemška časopisna hiša Frankfurter Allgemeine Zeitung – FAZ. Mladino iz različnih držav vabijo k pisanju intervjujev in člankov, najboljše objavijo v časopisu v rubriki Mladina piše in jih tudi denarno nagradijo.
                    </p>
                    <p>
                        Svoje prispevke v letošnjem šolskem letu objavljajo dijaki 3. in 4. letnikov. Anja Sagadin, Evelin Munda in Anja Skledar so napisale prispevek Pomahaj z nasmehom o svetovni popotnici, nekdanji dijakinji Gimnazije Ptuj Nini Jazbec iz Žetal, ki je bil objavljen v FAZ 21. septembra 2020.
                    </p>
                    <p>
                        Prispevek Nihče ni poziral o slovenskem fotografu mednarodnega slovesa, lanskoletnem Prešernovem nagrajencu in nekdanjem ptujskem gimnazijcu Stojanu Kerblerju, objavljen 9. novembra 2020, so napisali Matija Berden Strelec, Matic Kramberger  in Matija Peršak.
                    </p>
                    <p>
                        Sara Gutschi in Vida Glatz pa v prihodnjih dneh pričakujeta objavo članka o glasbeniku mednarodnega slovesa, nekdanjem ptujskem gimnazijcu Žanu Tetičkoviču. Dekleti pa že pripravljata nov  intervju, tokrat z nemškima veleposlanikoma  v Sloveniji Adrianom Pollmannom in Nathalie Kauther, v prihodnjem mesecu pa bosta prispevek poslali v objavo v FAZ.
                    </p>
                    <p>
                        Zanimanje za sodelovanje je veliko tudi med mlajšimi dijaki, saj je objava v tako priznanem časopisu velik dosežek in dobra referenca za mlade, ki si bodo ustvarjali kariero.
                    </p>
                </div>
            ),
            useBackground: false,
            bgClass: ""
        }
    ];

    constructor(props: any) {
        super(props);

        this.colorClass = State.getClassForLevel(6);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.add(this.colorClass);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.colorClass);
    }

    public render() {
        return (
            <>
                <div className={"gimptujImage smaller"}><img src={State.getGimptujImageWhite()} /></div>
                <div className="slogan medium"><img src={State.getSloganForLevel(this.level)} /></div>

                <div className="page-content">
                    <YouTubeEmbed src="https://www.youtube.com/embed/slBX1uopHM0?rel=0" />

                    <h1>Certifikati</h1>
                    <ItemList items={this.items} />
                    <br />

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/dsd/0.jpg",
                                    "/img/sliders/dsd/1.jpg",
                                    "/img/sliders/dsd/2.jpg",
                                    "/img/sliders/dsd/3.jpg",
                                    "/img/sliders/dsd/4.jpg",
                                    "/img/sliders/dsd/5.jpg",
                                    "/img/sliders/dsd/6.jpg",
                                    "/img/sliders/dsd/7.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/delf/0.jpg",
                                    "/img/sliders/delf/1.jpg",
                                    "/img/sliders/delf/2.jpg",
                                    "/img/sliders/delf/3.jpg",
                                    "/img/sliders/delf/4.jpg",
                                    "/img/sliders/delf/5.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <div className="carouselContainerBox">
                        <div className="carouselContainer limited">
                            <Carousel interval={null} className="darknext">
                                {[
                                    "/img/sliders/kitajscina/0.jpg",
                                    "/img/sliders/kitajscina/1.jpg",
                                    "/img/sliders/kitajscina/2.jpg",
                                    "/img/sliders/kitajscina/3.jpg",
                                    "/img/sliders/kitajscina/4.jpg"
                                ].map(link => (
                                    <Carousel.Item key={link}>
                                        <img
                                            className="d-block w-100"
                                            src={link}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>

                    <br />
                    <ItemGrid items={this.gridItems} />
                </div>
            </>
        );
    }

    GetDSD(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p><b>DSD I in DSD II • Nemška jezikovna diploma</b></p>
                    <p>Nemška jezikovna diploma (Deutsches Sprachdiplom/DSD) je izpit, s katerim dijaki lahko pridobijo certifikat o aktivnem znanju nemščine. Na Gimnaziji Ptuj jo dijaki lahko opravljajo na dveh nivojih. Manj zahteven izpit DSD1 na nivoju B1/A2  se izvaja v 3. letniku, DSD2 na nivoju C1/B2 pa v 4 letniku. V 20 letih, odkar na šoli poteka izpit, ga je uspešno opravilo že preko 500 gimnazijcev.</p>
                    <p>Vsi dijaki, ki želijo opravljati izpit, imajo v 2., 3. in 4. letniku dodatne ure DSD, vsako leto pa se pred izpitom z mentoricami za nekaj dni odpravijo na tradicionalni tabor na Pohorje, kjer potekajo intenzivne priprave. Na izpit dijake pripravljajo profesorice nemščine, občasno pa ure izvede programski učitelj iz Nemčije. Zadnjih nekaj let na šoli gostimo tudi mlade nemške prostovoljce. </p>
                    <p>DSD lahko opravljajo dijaki, ki pokažejo dovolj znanja iz nemščine, ne glede na to, ali je to njihov prvi ali drugi tuji jezik. Dijak, ki zelo dobro opravi izpit DSD 1, se lahko v 4. letniku odloči tudi za opravljanje izpita DSD2.</p>
                    <br />
                    <p><b>Zakaj je DSD koristen?</b></p>
                    <p>Certifikat DSD dijakom omogoča vpis na nemško govoreče univerze brez predhodnega opravljanja izpita iz nemščine, z diplomo DSD 2 se lahko dijaki potegujejo za štipendijo za celoten študij v Nemčiji, ki je vsako leto dodeljena enemu slovenskemu maturantu (dobili so jo že trije naši nekdanji dijaki), opravljen izpit pa je tudi dobra referenca pri študijskih izmenjavah in kasneje pri iskanju zaposlitve.</p>
                    <p>Dijaki lahko v okviru DSD sodelujejo v različnih projektih DSD-šol. Iz Nemčije dobimo tudi učbenike, slovarje in nekaj sredstev za jezikovni tabor. </p>
                </div>
            </>
        );
    }

    GetDELF(): JSX.Element {
        return (
            <>
                <div className="itemListContent">
                    <p>Jezikovno diplomo za francoski jezik DELF dijaki opravljajo v 4. letniku. Izbirajo lahko med različnimi stopnjami zahtevnosti: od A2, B1 do  B2, glede na pridobljeno znanje.</p>
                    <p>Dijaki znanje pridobivajo pri rednih urah pouka in dodatni uri v 2. in 3. letniku ter pri različnih dodatnih dejavnostih, v katerih navežejo stik z naravnimi govorci francoščine: delavnicah v okviru Francoskega inštituta, projektih, kot so Frankofonski dan, Francija potuje, nastopih, bralni znački, izmenjavah in ekskurzijah.</p>
                    <p>V četrtem letniku organiziramo še dodatno brezplačno pripravo na izpit. Ta poteka v aprilu, za dijake iz vzhodne Slovenije v Mariboru. Diploma je priznana po celem svetu in velja neomejeno. Tako pridobimo pisno potrdilo o znanju francoščine, ki ga lahko uporabimo tako v Sloveniji kot v tujini.</p>
                </div>
            </>
        );
    }
}