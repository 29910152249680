import * as React from 'react';
import './NavMenu.css';
import { State } from '../state';
import LevelBox from './partial/LevelBox';

export default class NavMenu extends React.PureComponent<{ onLevelChanged: (i: number) => void }> {
    public render() {
        return (
            <header>
                <div id="mainNavbar" className="box-shadow">
                    <div className="levelContainer">
                        <LevelBox levelNumber={0} caption="Uvod" onLevelChanged={(l) => this.props.onLevelChanged(l)} />
                        <LevelBox levelNumber={1} caption="Ravnateljev pozdrav" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={2} caption="Programi" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={3} caption="Učenje" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={4} caption="Dejavnosti" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={5} caption="Svet" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={6} caption="Jeziki" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={7} caption="Zvezde" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={8} caption="Šport" onLevelChanged={(l: number) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={9} caption="Umetnost" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={10} caption="Dijaki" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={11} caption="Vsakdan" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        <LevelBox levelNumber={12} caption="Vpis" onLevelChanged={(l) => this.props.onLevelChanged(l)}  />
                        {/* <LevelBox levelNumber={13} caption="Vpis" onLevelChanged={(l) => this.props.onLevelChanged(l)}  /> */}
                    </div>
                    <div id="sideBar" className={State.getClassForLevel(State.selectedLevel)}>
                    </div>
                </div>
            </header>
        );
    }
}
