import * as React from 'react';
import { Container } from 'reactstrap';
import { API } from '../helpers/apiHelper';
import { LevelData } from '../models/levelData';
import { State } from '../state';
import NavMenu from './NavMenu';

export default class Layout extends React.PureComponent<{ onLevelChanged: (i: number) => void, children?: React.ReactNode }> {
    isFetching: boolean = false;
    levels: LevelData[] = [];

    constructor(props: any) {
        super(props);

        this.getNavbar = this.getNavbar.bind(this);
    }

    async componentDidMount() {

        this.isFetching = true;
        this.levels = await API.getLevels();
        this.isFetching = false;
    }

    getNavbar() {
        if (State.isOnMainPage()) {
            return (
                <NavMenu onLevelChanged={(l: number) => this.props.onLevelChanged(l)} />
            );
        }
        else return (
            <div></div>
        );
    }

    getMainClass(): string {
        if (State.isOnMainPage()) return "";
        else return "normal";
    }

    public render() {
        return (
            <React.Fragment>
                <this.getNavbar />

                <Container id="main" className={this.getMainClass()}>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}